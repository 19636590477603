// @font-face definitions

@font-face {
    font-family: 'Adieu';
    src: url('../fonts/Adieu-Regular.woff2') format('woff2'),
    url('../fonts/Adieu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Otterco-Regular';
    src: url('../fonts/Otterco-Regular.woff2') format('woff2'),
    url('../fonts/Otterco-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Otterco-Light';
    src: url('../fonts/Otterco-Light.woff2') format('woff2'),
    url('../fonts/Otterco-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Otterco-Medium';
    src: url('../fonts/Otterco-Medium.woff2') format('woff2'),
    url('../fonts/Otterco-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Otterco-SemiBold';
    src: url('../fonts/Otterco-SemiBold.woff2') format('woff2'),
    url('../fonts/Otterco-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Otterco-Bold';
    src: url('../fonts/Otterco-Bold.woff2') format('woff2'),
    url('../fonts/Otterco-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Otterco-ExtraBold';
    src: url('../fonts/Otterco-ExtraBold.woff2') format('woff2'),
    url('../fonts/Otterco-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'lg';
    src: url('../fonts/lightgallery/lg.woff2?io9a6k') format('woff2'),
    url('../fonts/lightgallery/lg.ttf?io9a6k') format('truetype'),
    url('../fonts/lightgallery/lg.woff?io9a6k') format('woff'),
    url('../fonts/lightgallery/lg.svg?io9a6k#lg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
