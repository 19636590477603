@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

section.jobs-list-wrapper {
    .title-wrapper{
        margin: 0 0 50px;

        .tertiary-cta{
            .icon-external-link {
                svg{
                    path{
                        stroke: map-get($colors-buttons, link);
                    }
                }
            }

            &:hover {
                .icon-external-link {
                    svg{
                        path{
                            stroke: map-get($colors-buttons, link-hover);
                        }
                    }
                }
            }
        }
    }

    .jobs-filter {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .select-wrapper{

            max-width: 545px;
            width: 545px;
        }
    }

    .jobs-list {
        .job-single {
            @include grid(var(--column-amount), var(--gutter-width));
            align-items: center;
            border-bottom: 1px solid map-get($colors-base, border);
            padding: 24px 0;

            .title{
                margin: 0;
                text-transform: uppercase;
                @include col-start(1);
                @include col(3);
            }
            .parks {
                @include col-start(4);
                @include col(3);
            }
            .disponibility{
                margin: 0;
                @include col-start(7);
                @include col(3);
            }
            .actions {
                @include col-start(10);
                @include col(3);
                display: flex;
                justify-content: end;
            }
        }

        .pagination{
            margin-top: 30px;
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding-bottom: 80px;
        margin-bottom: -50px;
        .title-wrapper{
            margin: 0 0 25px;
        }

        .jobs-filter {
            display: flex;
            flex-direction: column;

            .select-wrapper{
                max-width: none;
                width: 100%;
            }
        }

        .jobs-list {
            .job-single {
                .title{
                    margin: 0 0 20px;
                    @include col-start(1);
                    @include col(12);
                }
                .parks {
                    margin: 0 0 20px;
                    @include col-start(1);
                    @include col(12);
                }
                .disponibility{
                    margin: 0 0 20px;
                    @include col-start(1);
                    @include col(12);
                }
                .actions {
                    @include col-start(1);
                    @include col(12);
                }
            }
        }
    }
}

