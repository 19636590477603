@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

label.checkbox-wrapper{
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    margin: 0 0 10px;

    &:hover {
        input {
            ~ .checkmark {
                background: map-get($colors-base, text);
                &:after {
                    display: block;
                }
            }
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            ~ .checkmark {
                background: map-get($colors-base, text);

                &:after {
                    display: block;
                }
            }
        }
    }

    .checkmark{
        position: absolute;
        top: -1px;
        left: 0;
        height: 20px;
        width: 20px;
        background: none;
        border: 1px solid map-get($colors-base, border);
        border-radius: 3px;

        &:after{
            content: "";
            position: absolute;
            display: none;

            left: 7px;
            top: 4px;
            width: 3px;
            height: 7px;
            border: solid map-get($colors-base, bg-beige);
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }

    @media only screen and (max-width: $sm-max) {

    }

    @media only screen and (max-width: $xs-max){

    }
}




