@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-accordion-image {
    --column-list: var(--column-amount);
    overflow: hidden;

    padding-top: 0;

    &.with-mountain {
        padding-top: 0;
    }

    &.align-right {
        .centered-content {
            .image-content {
                order: 2;
                @include col-start(8);
                right: auto;
                border-radius: 5px;
                left: calc(var(--gutter-width)*2);
            }

            .accordions-wrapper {
                order: 1;
                @include col-start(2);
            }
        }
    }

    .centered-content {
        .image-content {
            position: relative;
            @include col-start(1);
            @include col(5);
            right: calc(var(--gutter-width)*2);
            border-radius: 5px;
            overflow: hidden;
            aspect-ratio: 699/800;

            img {
                aspect-ratio: 699/800;
                height: 100%;
                min-height: 100%;
                max-width: none;
                scale:1.4;
                object-fit: cover;
            }
        }

        .accordions-wrapper {
            margin: 140px 0 0;
            @include col-start(7);
            @include col(5);
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding-top: 80px;

        &.with-mountain {
            padding-top: 80px;
        }

        &.align-right {
            .centered-content {
                .image-content {
                    order: 1;
                    @include col-start(1);
                    @include col(12);
                    right: auto;
                    left: auto;
                }

                .accordions-wrapper {
                    order: 2;
                    @include col-start(1);
                    @include col(12);
                }
            }
        }

        .centered-content {
            .image-content {
                display: block;
                height: 460px;
                @include col-start(1);
                @include col(12);
                right: auto;
                left: auto;
                aspect-ratio: unset;

                img {
                    height: unset;
                    min-width: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .accordions-wrapper {
                margin: 60px 0 0;
                @include col-start(1);
                @include col(12);
            }
        }
    }
}
