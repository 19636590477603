@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.rates-list {
    &.with-mountain {
        padding: 40px 0 0;
    }

    .rates-accordion {
        padding-block: 60px;

        &:last-child {
            border-bottom: 1px solid var(--border-color);
        }

        .accordion-content-wrapper {
            cursor: default;

            .accordion-title {
                text-transform: unset;
                display: flex;
                cursor: pointer;
                @include col(12);

                &.tags-wrap {
                    flex-direction: column;

                    .tags {
                        margin-top: 20px;
                        margin-left: 0;
                    }
                }

                p {
                    max-width: 95%;
                    @include adieu();
                    font-weight: 400;
                    font-size: rem(50);
                    line-height: rem(50);
                    // Force title to stay on the same line. If no more space available, wrap the parcs
                    //width: min-content;
                    //white-space: nowrap;
                }

                .tags {
                    display: inline-flex;
                    align-items: center;
                    margin-top: 3px;
                    margin-left: 20px;
                    padding-right: calc(var(--gutter-width) * 2);

                    .tag {
                        font-size: rem(14);
                        margin: 0;
                        color: map-get($colors-rates, text-dark);
                        background-color: map-get($colors-rates, tertiary-bg);
                        padding: 5px 10px 4px;
                        white-space: nowrap;
                    }
                }
            }

            .accordion-trigger {
                @include col(1);
                position: absolute;
                right: 0;
                top: 5px;
            }

            .accordion-content {
                @include col(12);

                .grid-container {
                    padding-top: 0;
                    padding-bottom: 40px;

                    &:nth-child(n+2) {
                        .parks-titles {
                            position: relative;
                        }

                        .park-titles {
                            position: relative;
                            padding-block: 20px 15px;
                        }
                    }

                    .park-titles {
                        @include otterco-light();
                        text-transform: uppercase;
                        padding-block: 40px 35px;
                        cursor: default;
                    }

                    .header-row {
                        position: relative;
                        color: map-get($colors-rates, text-secondary);
                        text-transform: uppercase;

                        &:after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background: map-get($colors-rates, border);
                            bottom: -1px;
                        }

                    }

                    .header-row, .body-row {
                        @include grid(var(--column-amount), var(--gutter-width));
                        align-items: center;
                        padding-block: 24px;

                        div {
                            line-height: rem(22);
                            letter-spacing: 0.05em;

                            &:nth-child(1) {
                                grid-column: 1 / span 2;
                                text-transform: uppercase;
                            }

                            &:nth-child(2) {
                                grid-column: 3 / span 2;
                            }

                            &:nth-child(3) {
                                grid-column: 5 / span 1;
                            }

                            &:nth-child(4) {
                                grid-column: 6 / span 5;
                                padding-right: 15px;
                            }

                            &:nth-child(5) {
                                grid-column: 11 / span 2;
                                justify-self: end;
                            }
                        }

                    }

                    .body-row {
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background: map-get($colors-rates, border);
                            bottom: -1px;
                        }

                        &:hover {

                            background-color: map-get($colors-rates, secondary-bg);
                            //box-shadow hack
                            box-shadow: 0 0 0 100vmax map-get($colors-rates, secondary-bg);
                            clip-path: inset(0 -100vmax);
                        }

                        div {
                            &:nth-child(1) {
                                font-size: rem(20);
                                line-height: rem(30);
                                padding-right: 5px;
                            }

                            &:nth-child(5) {
                                a, button {
                                    font-size: rem(14);
                                    line-height: rem(18);
                                    color: map-get($colors-rates, link-color-secondary);
                                    border-bottom: 1px solid map-get($colors-rates, link-color-secondary);
                                    padding-bottom: 3px;
                                    text-transform: uppercase;
                                    text-decoration: none;

                                    &:hover {
                                        color: map-get($colors-rates, link-color-secondary-hover);
                                        border-bottom: 1px solid map-get($colors-rates, link-color-secondary-hover);
                                    }
                                }
                            }
                        }
                    }
                }

                .text {
                    margin-block: 0 40px;

                    p {
                        margin: 0;

                        a {
                            color: var(--text-color);
                        }
                    }
                }

                .franchise {
                    margin-bottom: 40px;

                    p {
                        &.medium {
                            text-transform: uppercase;
                            margin-bottom: 15px;
                        }
                    }

                    a, button {
                        padding-bottom: 3px;
                        color: map-get($colors-rates, link-color-secondary);
                        border-bottom: 1px solid map-get($colors-rates, link-color-secondary);
                        text-decoration: none;

                        &:hover {
                            color: map-get($colors-rates, link-color-secondary-hover);
                            border-bottom: 1px solid map-get($colors-rates, link-color-secondary-hover);

                            &[target=_blank] {
                                &:after {
                                    content: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41797 9.66663L9.66794 1.41663M9.66794 1.41663H1.41797M9.66794 1.41663V9.66663' stroke='%23DFDF7B' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                }
                            }
                        }

                        &[target=_blank] {
                            &:after {
                                content: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41797 9.66663L9.66794 1.41663M9.66794 1.41663H1.41797M9.66794 1.41663V9.66663' stroke='%23F2F27B' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                width: 8.25px;
                                height: 8.25px;
                                margin-top: 1px;
                                margin-left: 6px;

                            }
                        }

                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .rates-accordion {
            padding-bottom: 30px;

            &.active {
                border-bottom-color: map-get($colors-rates, border);
            }

            .centered-content{
                width: 100%;
            }

            .accordion-content-wrapper {
                .accordion-title {
                    flex-direction: column;

                    p {
                        font-size: rem(32);
                        line-height: rem(32);
                        white-space: normal;
                        width: unset;
                    }

                    .tags {
                        margin-top: 20px;
                        margin-left: 0;
                        flex-wrap: wrap;
                        gap: 10px;

                        .tag {
                            margin-right: 0;
                        }
                    }
                }

                .accordion-content {
                    .grid-container {
                        .header-row {
                            display: none;
                        }

                        .body-row {
                            border-top: 1px solid map-get($colors-rates, border);
                            border-bottom: 1px solid map-get($colors-rates, border);
                            padding-block: 20px;

                            &:last-child {
                                border-bottom: none;
                                padding: 20px 0 0;
                            }

                            &:after {
                                display: none;
                            }

                            div {
                                &:not(:last-child) {
                                    margin-bottom: 20px;
                                }

                                &:nth-child(1) {
                                    @include col-start(1);
                                    @include col(12);
                                }

                                &:nth-child(2) {
                                    @include col-start(1);
                                    @include col(8);
                                }

                                &:nth-child(3) {
                                    @include col-start(9);
                                    @include col(4);
                                    justify-self: flex-end;
                                }

                                &:nth-child(4) {
                                    @include col-start(1);
                                    @include col(12);
                                }

                                &:nth-child(5) {
                                    @include col-start(1);
                                    @include col(12);
                                    justify-self: flex-end;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: $xs-max) {
        &.with-mountain {
            padding: 0;
        }
        .rates-accordion {
            .accordion-content-wrapper {
                .accordion-content {
                    .grid-container {
                        .body-row {
                            &:hover{
                                background-color: unset;
                                box-shadow: unset;
                                clip-path: unset;
                            }
                        }
                    }
                }
                .accordion-trigger {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}
