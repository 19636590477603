@use "../../config" as *;
@use "../../mixins" as *;

section, .section {
    --border-color: #{map-get($colors-component, dark)};
    --svg-color: #{map-get($colors-component, dark)};
    --text-color:#{map-get($colors-component, dark)};

    &.dark-green, &.light-green {
        --border-color: #{map-get($colors-component, light)};
        --svg-color: #{map-get($colors-component, light)};
        --text-color:#{map-get($colors-component, light)};
    }

    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 140px 0;

    transition: opacity $defaultTransitionSpeed $ease-in-out-quad;

    background: map-get($colors-base, bg);

    &.with-mountain {
        padding: 140px 0 0;
        margin-bottom: -1px;
    }

    &.dark-green{
        background: map-get($colors-base, bg-dark-green);
    }
    &.light-green{
        background: map-get($colors-base, bg-light-green);
    }
    &.beige{
        background: map-get($colors-base, bg-beige);
    }
    &.light-beige{
        background: map-get($colors-base, bg-light-beige);
    }
    &.blue{
        background: map-get($colors-base, bg-blue);
    }
    &.light-blue{
        background: map-get($colors-base, bg-light-blue);
    }
    &.yellow{
        background: map-get($colors-base, bg-yellow);
    }
    &.white{
        background: map-get($colors-base, bg-white);
    }

    &.in-transition{
        opacity: 0;
    }

    &.no-padding-top{
        padding-top:0;
    }

    .topo-wrapper {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100vw;
        overflow: hidden;
    }

    .mountain-icon-wrapper {
        margin: 10px 0 -6px;
        width: 100vw;
        overflow: hidden;

        .icon-spacer{

        }
    }

    .centered-content {
        position: relative;
        width: $container-lg;
        margin: 0 auto;

        @include grid(var(--column-amount), var(--gutter-width));
    }

    @media only screen and (max-width: $md-max) {
        .centered-content {
            width: $container-md;
        }
    }

    @media only screen and (max-width: $sm-max) {
        padding: 100px 0;

        &.with-mountain {
            padding: 100px 0 0;
            &.no-padding-top{
                padding-top:0;
            }
        }

        .centered-content {
            width: $container-sm;
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding: 50px 0;
        .centered-content {
            width: $container-xs;
            box-sizing: border-box;
        }
    }
}
