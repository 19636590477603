@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;


.gift-cards{
    position: relative;
    .big-tile{
        .text-wrapper{
            .text-bottom{
                .actions{
                    a{
                        &:not(.alternate){
                            display: none;
                        }
                    }

                }
            }
        }
    }
}
