@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.confirmation-page {
    background: map-get($colors-base, bg-light-beige);

    .centered-content {
        >.text-wrapper {
            @include col(6);
            @include col-start(2);

            .confirmation-wrapper {
                margin: 0 0 80px;

                h3 {
                    margin: 0 0 30px;
                }

                p{
                    margin: 0;

                    &:first-child{
                        margin: 0 0 25px;
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        text-transform: uppercase;
                        color: map-get($colors-base, light-text);

                        .icon-check {
                            svg{
                                path {
                                    stroke: map-get($colors-base, light-text);
                                }
                            }
                        }
                    }

                    span {
                        @include otterco-semibold();
                    }
                }

                .primary-cta{
                    margin: 15px 0 0;
                }
            }

            .activities-wrapper {
                > h5 {
                    margin: 0 0 10px;
                }

                > p {
                    margin: 0 0 40px;
                }
            }
        }

        >.image-wrapper{
            @include col(5);
            @include col-start(8);

            img{
                max-width: 715px;
            }
        }
    }

    span[class^=icon-]{
        &.icon-spacer{
            margin-top: 0;
        }
    }


    @media only screen and (max-width: $md-max) {
        .centered-content {
            >.text-wrapper {
                @include col(7);
                @include col-start(1);

                .activities-wrapper {
                    .activity-list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: var(--gutter-width);
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        &.section{
            padding: 45px 0 0;
        }

        .centered-content {
            >.text-wrapper {
                @include col(12);
                @include col-start(1);

                .confirmation-wrapper {
                    margin: 0 0 45px;
                }
            }

            >.image-wrapper{
                display: none;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            >.text-wrapper {
                @include col(12);
                @include col-start(1);

                .activities-wrapper {
                    .activity-list {
                        gap: 30px;
                    }
                }
            }

            >.image-wrapper{
                display: none;
            }
        }
    }
}
