@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.hero {
    &.home {
        min-height: 1093px;
        padding: 167px 0 128px;

        &.with-video {
            min-height: calc((100vw * (9/16)) + 320px);

            .bigger {
                font-size: vw(130px, false, 0px, 9.0277777778vw);

                @media only screen and (max-width: $xs-max) {
                    font-size: vw(50px, true, 0px, 13.3333333333vw);
                }
            }

            .text-wrapper {
                display: flex;
                padding: 65px 0 250px;
                flex-grow: 1;

                .title {
                    display: flex;
                    align-self: end;
                    width: 71.3%;
                }
            }

            video {
                aspect-ratio: 16/9;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                min-width: 100vw;

                &.video-desktop {
                    opacity: 1;
                }

                &.video-mobile {
                    opacity: 0;
                }
            }

            .mountain-icon-wrapper{
                height: 400px;
                bottom: 275px;
                overflow: visible !important;

                &:after{
                    display: block;
                    content: '';

                    position: absolute;
                    left: 0;
                    right: 0;
                    top: calc(100% - 2px);

                    height: 277px;
                    background: white;
                }

                .icon-spacer {
                    margin: 0 0 0 -900px;
                }
            }
        }

        >.centered-content {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            justify-content: space-between;
        }

        .person{
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            max-width: clamp(558px, 55vw, 920px);
        }

        .text-wrapper {
            padding: 65px 0 300px;

            .title {
                @include col(9);

                h1{
                    color: map-get($colors-base, text-invert);
                }
            }
        }


        .description {
            width: 100%;


            @media screen and (max-width: $xs-max){
                position: relative;
                top: 60px;
            }


            .centered-content{
                p{
                    font-size: rem(20);
                    line-height: rem(30);
                    margin: 0;
                }

                >div {
                    margin-left: -40px;
                    @include col-start(2);
                    @include col(5);
                }

                .cta-button {
                    margin-top: 50px;
                    a {
                        color: map-get($colors-base, text-invert);
                    }
                }
            }
        }

        .background-wrapper{
            img{
                max-width: 150%;
                width: 150%;
                object-fit: cover;
                position: absolute;
                top: 0;
                right:0;
                will-change: transform;
            }
        }

        .mountain-icon-wrapper {
            height: 500px;
            margin: 0 0 -1px 0;

            .icon-spacer {
                margin: 0 0 0 -5300px;
            }
        }

        @media only screen and (max-width: $sm-max) {
            min-height: 971px;
            padding: 81px 0 60px;

            &.with-video {
                min-height: calc((100vw * (9/16)) + 500px);

                .text-wrapper {
                    padding: 151px 0;

                    .title{
                        align-self: center;
                    }
                }

                .mountain-icon-wrapper{
                    height: 280px;

                    .icon-spacer {
                        margin: 0;
                    }
                }

            }

            .person {
                right: -40px;
                min-width: 558px;
            }

            .background-wrapper{
                img{
                    max-width: 200%;
                    width: 200%;

                }
            }


            .text-wrapper {
                padding: 151px 0 0;
                display: flex;
                align-items: center;

                .title {
                    width: 100%;
                }
            }

            .description {
                .centered-content{
                    margin: 0;

                    p, div {
                        margin: 0;
                        @include col-start(1);
                        @include col(12);
                    }

                    .cta-button {
                        margin: 40px 0 0;
                    }
                }
            }

            .mountain-icon-wrapper {
                height: 420px;

                .icon-spacer {
                    margin: 0 0 0 -4500px;
                }
            }
        }

        @media only screen and (max-width: $xs-max) {
            &.with-video {
                min-height: calc(100vh + 100px);

                video {
                    aspect-ratio: 9/16;
                    min-height: auto;
                    width: 120vw;

                    &.video-desktop {
                        opacity: 0;
                    }

                    &.video-mobile {
                        opacity: 1;
                    }
                }

                .mountain-icon-wrapper{
                    bottom: 200px;

                    &:after{
                        height: 202px;
                    }
                }
            }
        }
    }
}
