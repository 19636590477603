@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-text-highlight {
    position: relative;
    --column-list: var(--column-amount);

    .centered-content {
        .surtitle {
            @include col-start(1);
            @include col(9);
            text-transform: uppercase;
            margin: 0 0 20px;
        }

        h2 {
            @include col-start(1);
            @include col(9);
            margin: 0 0 80px;
        }

        .text-wrapper {
            @include col-start(5);
            @include col(8);

            .text-content {
                margin: 0 0 32px;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            .surtitle {
                @include col-start(1);
                @include col(12);
                margin: 0 0 10px;
            }

            h2 {
                @include col-start(1);
                @include col(12);
                margin: 0 0 30px;
            }

            .text-wrapper {
                @include col-start(1);
                @include col(12);
            }
        }
    }
}
