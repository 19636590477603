@use "../../config" as *;
@use "../../mixins" as *;

.wysiwyg {
    padding-bottom: 0;

    .centered-content{
        h5 {
            display: grid;
            @include col(9);
            margin: 0 0 30px;
        }

        .wysiwyg {
            display: grid;
            @include col(9);
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content{
            h5 {
                @include col(12);
            }

            .wysiwyg {
                @include col(12);
            }
        }
    }
}
