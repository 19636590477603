@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.lg-overlay-open {
    .lg-backdrop {
        background-color: map-get($colors-component, dark);
    }

    .lg-container {

        .lg-inner {
            display: grid !important;
            place-items: center;
        }

        .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition{
            transform: scale3d(1, 1, 1) translate3d(-50%, 0, 0px) !important;
        }

        .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-y, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-y{
            transform: scale3d(1, 1, 1) translate3d(0, 0, 0px) !important;
        }

        .lg-outer {
            .lg-item {
                height: unset;
                width: fit-content;
            }

            .lg-img-wrap {
                inset: unset;
                position: relative;
                width: unset;
                height: unset;
            }


            .lg-sub-html {
                padding: 0;
                text-align: left;
                bottom: -30px;
            }

            .lg-object {
                max-height: 75vh;
                max-width: 85vw;
                width: auto !important;
                height: auto !important;
            }
        }

        .lg-zoom-out{
            display: none;
        }

        .lg-zoomed{
            .lg-zoom-in{
                display: none;
            }
            .lg-zoom-out{
                display: block;
            }
        }


        .lg-sub-html {
            @include otterco-regular();
            font-size: rem(14);
            line-height: rem(19.6);
        }

        .lg-counter {
            @include otterco-light();
            font-size: rem(18);
            color: map-get($colors-base, text-invert);
            display: flex;
            align-items: center;
            letter-spacing: 5px;
            top: 60px;
            left: 55px;
            position: absolute;

            &:before {
                content: "";
                background-image: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.6875" y="6.5" width="18" height="18" rx="1.5" stroke="white"/><path d="M5.1875 6V3C5.1875 1.89543 6.08293 1 7.1875 1H22.1875C23.2921 1 24.1875 1.89543 24.1875 3V18C24.1875 19.1046 23.2921 20 22.1875 20H19.1875" stroke="white"/></svg>');
                //background-image: url('../images/ui/gallery-count.png');
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: 12px;
            }
        }

        .lg-next {
            left: unset;
            position: absolute;
            right: 35px;
            top: 120px;
            background-color: transparent;

            &:before {
                content: "";
                background-image: url('data:image/svg+xml,<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="59.8304" y="59.6429" width="59.2857" height="59.2857" rx="29.6429" transform="rotate(-180 59.8304 59.6429)" stroke="white" stroke-width="0.714286"/><path d="M24.6598 14.2857L43.0469 30M43.0469 30L24.6598 45.7143M43.0469 30L24.4754 30" stroke="white" stroke-width="0.714286" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                //background-image: url('../images/ui/next-arrow.png');
                display: inline-block;
                width: 61px;
                height: 60px;
                transition: all ease 250ms;
            }

            &:hover {
                &:before {
                    transition: all ease 250ms;
                    background-image: url('data:image/svg+xml,<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="59.8304" y="59.6429" width="59.2857" height="59.2857" rx="29.6429" transform="rotate(-180 59.8304 59.6429)" stroke="white" stroke-width="0.714286"/><path d="M24.6598 14.2857L43.0469 30M43.0469 30L24.6598 45.7143M43.0469 30L24.4754 30" stroke="white" stroke-width="0.714286" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    //background-image: url('../images/ui/next-arrow-hover.png');
                }
            }

            &:active {
                opacity: 0.5;
                transition: all ease 250ms;
            }

        }

        .lg-prev {
            left: unset;
            position: absolute;
            right: 35px;
            top: calc(120px + 60px) + 15px; // 120 is lg-next value. 60 is padding from top. 15px is gap between buttons
            background-color: transparent;

            &:after {
                content: "";
                background-image: url('data:image/svg+xml,<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.544643" y="0.357143" width="59.2857" height="59.2857" rx="29.6429" stroke="white" stroke-width="0.714286"/><path d="M35.7152 45.7143L17.3281 30M17.3281 30L35.7152 14.2857M17.3281 30L35.8996 30" stroke="white" stroke-width="0.714286" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                //background-image: url('../images/ui/prev-arrow.png');
                display: inline-block;
                width: 61px;
                height: 60px;
                transition: all ease 250ms;
            }

            &:hover {
                &:after {
                    transition: all ease 250ms;
                    background-image: url('data:image/svg+xml,<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.544643" y="0.357143" width="59.2857" height="59.2857" rx="29.6429" stroke="white" stroke-width="0.714286"/><path d="M35.7152 45.7143L17.3281 30M17.3281 30L35.7152 14.2857M17.3281 30L35.8996 30" stroke="white" stroke-width="0.714286" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    //background-image: url('../images/ui/prev-arrow-hover.png');
                }
            }

            &:active {
                opacity: 0.5;
                transition: all ease 250ms;
            }

        }

        .lg-close {
            top: 37px;
            right: 50px;
            filter: brightness(1);
            transition: all $defaultTransitionSpeed $ease-in-out-quad;
            position: absolute;
            float: none;
            left: unset;

            &:after {
                content: "";
                background-image: url('data:image/svg+xml,<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="50.3301" y1="0.353553" x2="1.53974" y2="49.1439" stroke="%23F2F27B"/><line x1="49.623" y1="49.144" x2="0.832646" y2="0.353587" stroke="%23F2F27B"/></svg>');
                //background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MSA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjUwLjMzMDEiIHkxPSIwLjM1MzU1MyIgeDI9IjEuNTM5NzQiIHkyPSI0OS4xNDM5IiBzdHJva2U9IiNGMkYyN0IiLz4KPGxpbmUgeDE9IjQ5LjYyMyIgeTE9IjQ5LjE0NCIgeDI9IjAuODMyNjQ2IiB5Mj0iMC4zNTM1ODciIHN0cm9rZT0iI0YyRjI3QiIvPgo8L3N2Zz4K");
                //background-image: url('../images/ui/close.png');
                display: inline-block;
                width: 52px;
                height: 50px;
            }

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(1.1);
            }
        }

        .lg-zoom-in, .lg-zoom-out {
            // Subtract image height + bottom gap height
            top: calc(100dvh - 100px);
            right: 50px;
            filter: brightness(1);
            transition: all $defaultTransitionSpeed $ease-in-out-quad;
            position: absolute;
            float: none;
            left: unset;

            &:after {
                content: "";
                background-image: url('data:image/svg+xml,<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44.0365 45.1687L31.1344 32.2667C29.4885 33.7479 27.5958 34.8726 25.4563 35.6406C23.3167 36.4087 21.1771 36.7927 19.0375 36.7927C13.9903 36.7927 9.72483 35.0509 6.24115 31.5672C2.75746 28.0835 1.01562 23.8455 1.01562 18.8531C1.01562 13.8059 2.75746 9.54044 6.24115 6.05676C9.72483 2.57308 13.9628 0.831238 18.9552 0.831238C24.0024 0.831238 28.2816 2.57308 31.7927 6.05676C35.3038 9.54044 37.0594 13.7785 37.0594 18.7708C37.0594 21.0201 36.6479 23.2283 35.825 25.3953C35.0021 27.5623 33.8774 29.4139 32.451 30.95L45.3531 43.7698L44.0365 45.1687ZM19.0375 34.9823C23.591 34.9823 27.4313 33.4324 30.5583 30.3328C33.6854 27.2331 35.249 23.3792 35.249 18.7708C35.249 14.2173 33.6854 10.3908 30.5583 7.29113C27.4313 4.19148 23.591 2.64165 19.0375 2.64165C14.484 2.64165 10.6438 4.19148 7.51667 7.29113C4.38958 10.3908 2.82604 14.2173 2.82604 18.7708C2.82604 23.3792 4.38958 27.2331 7.51667 30.3328C10.6438 33.4324 14.484 34.9823 19.0375 34.9823Z" fill="white" stroke="%23132C2A" stroke-width="0.7"/></svg>');
                //background-image: url('../images/ui/zoom.png');
                display: inline-block;
                width: 44.34px;
                height: 44.34px;
            }

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(1.1);
            }
        }
    }

    @media only screen and (max-width: $sm-max){
        .lg-container{
            .lg-outer{
                .lg-object {
                    max-height: 70vh;
                }
            }
            .lg-counter{
                top: 40px;
                left: 7px;
                transform: scale(0.8);
            }
            .lg-close{
                top: 40px;
                right: 20px;
                transform: scale(0.6);
            }
            .lg-next, .lg-prev{
                transform: scale(0.85);
                top: calc(100dvh - 75px);
            }
            .lg-next{
                right: 20px;
            }
            .lg-prev{
                right: 85px;
            }
            .lg-zoom-in, .lg-zoom-out{
                //top: calc(100dvh - 25px);
                transform: scale(0.95);
                left: 25px;
            }
        }

    }
    @media only screen and (max-width: $xs-max){
        .lg-overlay-open{
            .lg-container{
                .lg-inner{
                    transform: translateY(-5%);
                }
            }
        }
        .lg-container{
            .lg-outer{
                top: -20px;
                height: 100dvh;
                .lg-object {
                    max-height: 64vh;
                }
            }
        }
    }
}
