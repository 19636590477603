@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

section.park-list-hero.type_2 {
    overflow: visible;

    .title {
        .subtext{
            position: relative;
            margin-bottom: 50px;

            h3{
                display: inline-block;
                margin: 0 20px 0 0;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;
        align-items: start;

        .select-wrapper{
                .choices{
                .choices__inner{
                    min-width: 320px;
                    .choices__list{
                        &::-webkit-scrollbar-thumb {
                            background-color: transparent;
                        }
                        .choices__item{
                            color:  map-get($colors-base, light-text);
                            font-size: rem(16);
                        }
                    }
                }
            }
            .icon-arrow-down{
                svg{
                    path{
                        stroke: map-get($colors-base, bg-dark-green);
                    }
                }
            }
        }

        .primary-cta {
            display: flex;

            &.mobile {
                display: none;
            }
        }
    }

    [data-nb-items] {
        color: map-get($colors-base, light-text)
    }

    .svg-divider{
        width: 100%;
        overflow: hidden;
    }

    @media only screen and (max-width: $sm-max) {
        .title {
            .subtext{
                h3{
                    margin: 0 10px 0 0;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .text-wrapper {
            .title {
                @include col(12);

                h1 {
                    margin: 0 0 20px;
                }
                .subtext{
                    margin: 0;

                    h3{
                        margin: 0 0 3px;
                    }
                    .select-wrapper{
                        margin: 0 0 60px;
                    }
                }
            }
        }
        .actions {
            .select-wrapper{
                .choices{
                    .choices__inner{
                        min-height: 45px;
                        min-width: 245px;
                        max-width: 245px;
                        .choices__list{
                            .choices__item{
                                font-size: rem(16);
                            }
                        }
                    }
                }
                span{
                    &.icon-arrow-down{
                        top: 18px;
                    }
                }
            }

            .primary-cta {
                display: none;

                &.mobile {
                    padding: 0;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon-map{
                        margin: 0 1px 0 0;
                    }
                }
            }
        }
    }
}
