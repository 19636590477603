@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

section.activity-intro{
    padding-top:0;

    .centered-content{
        .wrapper{
            @include col(8);
            @include col-start(5);

            .infos{
                display:flex;
            }

            .info{
                position: relative;
                display:flex;
                align-items: flex-start;
                padding-left: 70px;
                margin-right: 30px;
                width: 30%;

                >span{
                    &:first-child{
                        position: absolute;
                        left:0;
                        top:1px;
                        width: 48px;
                        height: 48px;
                        padding:10px;
                        box-sizing: border-box;
                        background: map-get($colors-base, bg);
                        border-radius: 50%;
                    }
                }
                .icon-price{
                    svg {
                        * {
                            stroke: none;
                            fill: map-get($colors-base, text);

                            &:last-child{
                                stroke: map-get($colors-base, text) !important;
                                fill: none;
                                stroke-width: 0.7px !important;
                            }
                        }
                    }
                }

                svg{
                    *{
                        stroke: map-get($colors-base, text);
                    }
                }

                span{
                    @include otterco-medium();
                    font-size: rem(16);
                    line-height: rem(20);

                    p{
                        margin: 4px 0 0;
                    }
                }
            }

            .description{
                margin-top: 80px;

                >*:first-child{
                    margin-top:0;
                }

                >*:last-child{
                    margin-bottom:0;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max){
        .centered-content {
            .wrapper {
                @include col(10);
                @include col-start(3);
            }
        }
    }

    @media only screen and (max-width: $xs-max){
        padding-top: 40px;
        .centered-content{
            .wrapper{
                @include col(12);
                @include col-start(1);

                .infos{
                    display:flex;
                    flex-direction: column;

                    .info{
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
