@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.breadcrumb-nav{
    ul{
        display: flex;
        padding: 0;
        margin-block: 50px 10px;

        li{
            color: var(--text-color);
            //color: map-get($colors-base, light-text);

            margin: 0;
            padding: 0;
            font-size: rem(18);
            line-height: rem(20);

            &:not(:last-child){
                margin-right: 10px;
            }

            &:before{
                display: none;
            }

            a{
                display: flex;
                align-items: center;
                text-decoration: none;
                @include otterco-light();

                .label{
                    //color: map-get($colors-base, light-text);
                    color: var(--text-color);
                    transition: color $defaultTransitionSpeed $ease-in-out-quad;

                    &:hover{
                        color: map-get($colors-base, text);
                    }
                }

                .icon-arrow-right{
                    margin-left: 10px;
                    svg{
                        width: 10px;
                    }
                }


            }
            .active{
                @include otterco-semibold();
            }
        }
    }
    @media only screen and (max-width: $xs-max) {
        ul{
            width: fit-content;
            margin-block: 80px 10px;
            max-width: 100%;

            &.has-parent-page {
                li {
                    &:nth-last-child(-n + 2) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        .icon-arrow-right {
                            display: none;
                        }
                    }

                    &:last-child {
                        display: none;
                    }
                }
            }

            li{
                font-size: rem(16);
                line-height: rem(22);

                span:not(.icon-arrow-right) {
                    display: inline-block;
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &:last-child {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
}
