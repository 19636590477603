@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.input-wrapper {
    &.file-input {
        &.has-error {
            label, .file-name {
                &:not(.button) {
                    color: map-get($colors-base, error);
                }
            }

            .error-msg {
                color: map-get($colors-base, error);
            }

        }
        &.alternate{
            .file-upload {
                .button{
                    background-color: map-get($colors-forms, background);
                    border-color: map-get($colors-forms, background);
                    color: map-get($colors-forms, label);
                    &:hover{
                        background-color: map-get($colors-forms, background-active);
                        border-color: map-get($colors-forms, background-active);
                    }
                }
            }
        }

        .file-upload {
            display: flex;
            align-items: center;
            gap: 10px;

            input[type=file] {
                &::file-selector-button {
                    display: none;
                }

                &.has-value {
                    & + label {
                        opacity: 0;
                    }
                }
            }

            input[type="file"] {
                display: none;

                &.has-value {
                    &~ .file-input-label {
                        display: none;
                    }
                }
            }

            .file-text {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;

                padding: 20px 30px;
                border-radius: 40px;
                border: 1px solid map-get($colors-forms, border);

                .file-input-label{
                    font-size: rem(14);
                    line-height: rem(20);
                    @include otterco-regular();
                    white-space: nowrap;
                }
            }

            .file-name {
                position: relative;
                left: unset;
                top: unset;
                transform: none;

                font-size: rem(14);
                line-height: rem(20);
                @include otterco-regular();
                white-space: nowrap;

                &.button{
                    margin-right: 20px;
                }
            }

            button {
                &.remove {
                    position: relative;
                    height: 100%;
                    opacity: 0.4;
                    padding: 0 20px;
                    margin-left: 10px;
                    z-index:1;

                    &:hover {
                        opacity: 1;
                    }

                    &:before, &:after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        display: block;
                        content: '';
                        height: 20px;
                        width: 2px;
                        background-color: #333;
                    }

                    &:before {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }

                    &:after {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }
        }


        .file-input-label {
            pointer-events: auto;

            &.button {
                position: relative;
                top: unset;
                left: unset;
                transform: unset;

                white-space: nowrap;

                font-size: rem(18);
                text-transform: uppercase;
            }
        }


        @media only screen and (max-width: $sm-max) {
            .file-upload {
                flex-direction: column;
                row-gap: 10px;
                align-items: flex-start;

            }
            .file-input-label {

                &.button {
                    width: 100%;
                    left: unset;
                    padding: 20px 30px;
                    
                }
            }
        }
    }
}

