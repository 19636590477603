@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.groups-list{
    padding-bottom: 0;
    .category-title{
        padding-block: 60px 30px;
        border-bottom: 1px solid map-get($colors-base, bg);
        text-transform: uppercase;
    }
    .tiles-wrapper{
        margin-block: 0 100px;
    }

    @media only screen and (max-width: $sm-max){
        .tiles-wrapper{
            margin-block: 0 60px;
        }

    }
}
