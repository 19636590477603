@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.park-navigation {
    padding: 0;
    background: map-get($colors-base, bg-dark-green);

    .park-primary-navigation {
        padding: 10px 0;
        margin: 0;
        background: map-get($colors-base, bg-dark-green);
        transition: top $defaultTransitionSpeed*2 $ease-in-out-quad;

        &.fixed {
            position: fixed;
            z-index: 99;
            left: 0;
            top: 0;
            right: 0;

            &.hide-up {
                top: -87px;
            }
        }

        .centered-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .logo {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: auto;

                .logo-icon {
                    padding: 0 20px 0 0;

                    span[class^=icon-] {
                        margin: 0;
                    }

                    svg{
                        path, rect, circle{
                            fill: map-get($colors-base, text-invert);
                        }
                    }
                }

                .park-title{
                    font-size: rem(20);
                    line-height: rem(26);
                    padding: 0 0 0 20px;
                    color: map-get($colors-base, text-invert);
                    border-left: 1px solid map-get($colors-base, text-invert);
                }
            }

            .park-primary-navigation-wrapper {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
                padding: 0;
                margin: 0;
                overflow-y: auto;

                li {
                    flex: 0 0 auto;
                    padding: 0;
                    margin: 0 40px 0 0;

                    &:before{
                        display: none;
                    }

                    &:last-child{
                        margin: 0;
                    }

                    a:not(.primary-cta):not(.logo-icon){
                        color: map-get($colors-base, text-invert);
                        text-decoration: none;
                        text-transform: uppercase;
                        @include otterco-medium();
                        font-size: rem(16);
                        line-height: rem(24);

                        &:after{
                            display: block;
                            content: '';
                            position: relative;
                            bottom: -5px;
                            width: 100%;
                            height: 2px;
                            background: map-get($colors-buttons, primary-alt-bg);
                            transform-origin: center;
                            transform: scaleX(0);
                            transition: all $defaultTransitionSpeed $ease-in-out-quad;
                        }

                        &:hover, &.active{
                            &:after{
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .park-primary-navigation {
            &.fixed {
                &.hide-up {
                    top: -136px;
                }
            }

            .centered-content {
                flex-direction: column;

                .logo {
                    align-items: start;
                    flex-direction: column;
                    width: 100%;

                    .icon-logo-arbraska {
                        margin: 0;
                    }

                    .park-title{
                        padding: 0;
                        margin: 0 0 20px;
                        border: none;
                    }
                }

                .park-primary-navigation-wrapper {
                    padding-bottom: 10px;

                    li {
                        margin-right: 20px;

                        &:nth-child(4){
                            margin: 0;
                        }

                        &:last-child{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
