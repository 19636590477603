@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.activity-cart {
    background: map-get($colors-base, bg-beige);
    overflow: clip;

    form {
        @include grid(var(--column-amount), var(--gutter-width));
    }

    @media only screen and (max-width: 1220px) {
        padding: 30px 0;
    }
}

