@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagination {
    display: flex;
    justify-content: flex-end;

    .wrapper {
        display: flex;
        align-items: center;

        .numbers {
            a, span {
                color: var(--text-color);
                text-decoration: none;

                &:not(:last-child) {
                    margin-right: 20px;
                }

                &:last-child {
                    margin-right: 30px;
                }

                &.is-active {
                    color: map-get($colors-pagination, active);
                }
            }
        }

        .swiper-arrows {
            display: flex;
            gap: 10px;
        }
    }
}
