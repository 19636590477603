@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.error-page {
    height: 100vh;
    background: none;

    .centered-content{
        position: unset;
    }

    .main-image{
        position: absolute;
        inset:0;
        z-index: -1;

        &:before {
            position: absolute;
            content: "";
            inset: 0;
            background: linear-gradient(0deg, rgba(56, 67, 58, 0.4), rgba(56, 67, 58, 0.4)), linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 25.96%);
            z-index: 1;
        }

        img{
            width: 100%;
            height: 100%;
            object-position: top;
            object-fit: cover;
        }
    }
    h1{
        margin-block: 100px 50px;
        color: map-get($colors-base, text-invert);
    }

    .description{
        @include col-start(2);
        @include col(6);

        p{
            margin-bottom: 50px;
            color: map-get($colors-base, text-invert);
        }
    }

    .mountain-icon-wrapper{
        .icon-spacer {
            width: 5000px;
            margin: 0;
            transform: translate3d(-50%, 0px, 0px) !important;
        }
    }
    @media only screen and (max-width: $sm-max){
        h1{
            @include col-start(1);
            @include col(12);
            width: 100%;
            margin-block: 50px 10px;
        }

        .description{
            @include col-start(1);
            @include col(12);

            p{
                margin-bottom: 30px;
            }
        }
    }
}
