@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

label.radio-wrapper{
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    margin: 0 0 10px;

    &:hover {
        input {
            ~ .radio-btn {
                &:after {
                    display: block;
                }
            }
        }
    }

    p{
        margin: 0;
        display: inline;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            ~ .radio-btn {
                &:after {
                    display: block;
                }
            }
        }
    }

    .radio-btn{
        position: absolute;
        top: 50%;
        left: 0;
        height: 20px;
        width: 20px;
        background: none;
        border: 1px solid map-get($colors-base, text);
        border-radius: 50%;
        transform: translateY(-50%);

        &:after{
            content: "";
            position: absolute;
            display: none;

            left: 5px;
            top: 5px;
            width: 8px;
            height: 8px;
            background: map-get($colors-base, text);
            border-radius: 50%;
        }
    }

    @media only screen and (max-width: $sm-max) {

    }

    @media only screen and (max-width: $xs-max){

    }
}




