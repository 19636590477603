@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.booking-park-accordion {
    &.active {
        border-bottom-color: map-get($colors-base, bg-dark-beige);
    }

    .centered-content {
        .accordion-content-wrapper {
            grid-column-gap: 0;

            .accordion-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                p:last-child{
                    margin: 5px -10px 0 20px;
                    text-transform: none;
                }
            }

            .accordion-title {
                @include col(11);
            }

            .accordion-content {
                @include col(12);

                .error-content {
                    p{
                        a {
                            font-size: rem(16);
                            line-height: 140%;
                        }
                    }
                }
            }

            .accordion-trigger {
                @include col(1);
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            .accordion-content-wrapper {
                .accordion-title {
                    p:last-child{
                        margin: 0 0 0 20px;
                    }
                }
                .accordion-content {
                    @include col(12);
                }
            }
        }
    }
}
