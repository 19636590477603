@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

span[class^="icon-"] {
    display: inline-block;

    svg {
        box-sizing: content-box;
        display: block;
        width: 100%;
        margin: 0;
    }

    &.icon-logo-arbraska{
        width: 185px;

        path, rect {
            fill: map-get($colors-base, text);
            transition: fill $defaultTransitionSpeed $ease-in-out-quad;
        }

        @media only screen and (max-width: $sm-max) {
            width: 124px;
        }
    }

    &.icon-logo-treetop{
        width: 185px;

        path, circle {
            fill: map-get($colors-base, text);
            transition: fill $defaultTransitionSpeed $ease-in-out-quad;
        }

        @media only screen and (max-width: $sm-max) {
            width: 124px;
        }
    }

    &.icon-spacer{
        width: 5000px;
        margin-top: 140px;

        @media only screen and (max-width: $sm-max) {
            width: 2500px;
            margin-top: 20px;

            &.hero{
                width: 100%;
                height: unset;
                margin-top: 0;
            }
        }

        &.hero-svg{
            width: 100%;
        }
        &.darker-green{
            path{
                fill: map-get($colors-base, bg-darker-green);
            }
        }
        &.dark-green{
            path{
                fill: map-get($colors-base, bg-dark-green);
            }
        }
        &.light-green{
            path{
                fill: map-get($colors-base, bg-light-green);
            }
        }
        &.beige{
            path{
                fill: map-get($colors-base, bg-beige);
            }
        }
        &.light-beige{
            path{
                fill: map-get($colors-base, bg-light-beige);
            }
        }
        &.white {
            path {
                fill: map-get($colors-base, bg);
            }
        }
        &.blue{
            path{
                fill: map-get($colors-base, bg-blue);
            }
        }
        &.light-blue{
            path{
                fill: map-get($colors-base, bg-light-blue);
            }
        }
        &.yellow{
            path{
                fill: map-get($colors-base, bg-yellow);
            }
        }
    }

    &.icon-external-link{
        width: 9px;

        svg{
            path{
                transition: all $defaultTransitionSpeed $ease-in-out-quad;
                stroke: map-get($colors-base, text);
            }
        }
    }

    &.icon-menu{
        width: 17px;
    }

    &.icon-arrow-down{
        width: 28px;
    }

    &.icon-play {
        width: 17px;
    }

    &.icon-smile {
        width: 22px;
    }
    &.icon-clock {
        width: 22px;
    }
    &.icon-thumbs-up {
        width: 21px;
    }
    &.icon-price {
        width: 20px;
    }

    &.icon-mobile-menu {
        width: 24px;

        line {
            stroke: map-get($colors-base, text);
            transition: all $defaultTransitionSpeed $ease-in-out-quad;
        }
    }

    &.icon-newsletter-icon {
        width: 122px;

        @media only screen and (max-width: $xs-max) {
            width: 63px;
        }

    }

    &.icon-facebook {
        width: 15px;
    }

    &.icon-instagram {
        width: 25px;
    }

    &.icon-twitter {
        width: 27px;
    }

    &.icon-ttg-logo {
        width: 100px;
    }

    &.icon-topo{
        position: absolute;
        height: 75%;
        aspect-ratio: 1.5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg{
            rect {
                width: 100%;
            }
        }
    }

    &.icon-map-pin {
        width: 22px;
    }

    &.icon-map {
        width: 19px;
    }

    &.icon-calendar {
        width: 24px;
    }

    &.icon-warning {
        width: 18px;
    }

    &.icon-check {
        width: 23px;
    }

    &.icon-tile-mask {
        z-index: 2;

        width: 220%;
        aspect-ratio: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: width $defaultTransitionSpeed * 2 ease-in-out;

        svg {
            * {
                stroke: none !important;
            }
        }

        &.blue{
            path {
                fill: map-get($colors-tile-hover, fill-blue);
            }
        }

        &.orange{
            path {
                fill: map-get($colors-tile-hover, fill-orange);
            }
        }

        &.yellow{
            path {
                fill: map-get($colors-tile-hover, fill-yellow);
            }
        }

        &.green{
            path {
                fill: map-get($colors-tile-hover, fill-green);
            }
        }

        &.dark-green{
            path {
                fill: map-get($colors-tile-hover, fill-dark-green);
            }
        }
    }

    &.icon-visa {
        width: 35px;
    }

    &.icon-mastercard {
        width: 35px;
    }

    @media only screen and (max-width: $sm-max) {

    }
}
