@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.option-single {
    display: flex;
    gap: 30px;
    margin: 0 0 20px;

    &:last-child{
        margin: 0;
    }

    &.number {
        .text-wrapper {
            .action{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;

                .line {
                    flex: 1 0 auto;
                    height: 1px;
                    background-color: map-get($colors-base, text);
                }
            }
        }
    }

    &.checkbox {
        .text-wrapper {
            .action{
                display: flex;
                align-items: center;

                .price {
                    margin: 0;
                }

                label {
                    margin: 0;

                    .checkmark {
                        top: 0;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    &.radio {
        .text-wrapper {
            label {
                margin: 0;

                [data-get-price]{
                    font-size: rem(20);
                    line-height: rem(30);
                }
            }
        }
    }


    &.textarea {
        margin: 40px 0 0;

        .title {
            text-transform: uppercase;
        }
    }

    .image-wrapper {
        position: relative;
        width: 150px;
        height: 150px;
        overflow: hidden;

        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
            max-height: 100%;
            height: 100%;
        }
    }

    .text-wrapper {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .medium {
            margin: 0;
            text-transform: uppercase;
        }

        .description {
            margin: 0;

            p{
                margin: 0;
                font-size: rem(16);
            }
        }

        .action {
            p {
                margin: 0;
            }


        }
    }

    @media only screen and (max-width: $xs-max) {
        .image-wrapper {
            flex: 0 0 auto;
            width: 125px;
            height: 125px;
        }

        .text-wrapper {
            flex: 0 1 auto;

            .line {
                display: none;
            }
        }

        &.textarea {
            .text-wrapper {
                width: 100%;
            }
        }
    }
}
