@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.activity-cart, .payment-cart {
    .cart-wrapper {
        position: sticky;
        top: 50px;

        @include col(4);
        @include col-start(8);
        margin-left: var(--gutter-width);
        margin-right: calc(-1 * var(--gutter-width));
        background: map-get($colors-base, bg);
        padding: 40px;
        border-radius: 5px;

        .title {
            margin: 0 0 30px;
        }

        .park {
            margin: 0 0 10px;
        }

        .activity-title {
            margin: 0 0 10px;
            font-size: rem(28);
            line-height: rem(28);
            text-transform: uppercase;
        }

        .date {
            margin: 0 0 20px;
            @include otterco-medium();
            font-size: rem(16);
            line-height: rem(22.4);
            text-transform: capitalize;
        }

        .cart-list-wrapper {
            margin: 0 0 30px;

            .list {
                .list-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: 0 0 5px;

                    p{
                        margin: 0;
                    }

                    [data-item-price]{
                        text-align: right;
                    }
                }
            }

            .subtotal{
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                p{
                    @include otterco-medium();
                    margin: 0;
                    font-size: rem(20);
                    line-height: rem(30);
                }

                [data-subtotal-price]{
                    text-align: right;
                }
            }

            .taxes {
                margin: 30px 0;
                border-top: 1px solid map-get($colors-base, bg-dark-beige);
                padding: 30px 0 0;

                .taxe-single {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: 0 0 5px;

                    p{
                        margin: 0;
                    }
                }
            }

            .total {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                h5{
                    margin: 0;
                }
            }
        }

        .accordion {
            padding: 0;
            @include grid(var(--column-amount), 0);
            border-bottom: none;
            border-top: 1px solid map-get($colors-base, text);

            &.season-pass{
                border-top: 1px solid map-get($colors-base, bg-dark-beige);
            }

            .centered-content {
                @include grid(var(--column-amount), 0);

                .accordion-content-wrapper {
                    grid-column-gap: 0;

                    .accordion-title {
                        padding: 20px 0;

                        p {
                            text-transform: none;
                        }
                    }

                    .accordion-trigger {
                        height: 100%;
                        margin: 0;
                        align-items: center;
                    }

                    .accordion-content{
                        @include col(12);
                        padding: 0 0 5px;

                        .secondary-cta {
                            font-size: 1.125rem;
                            line-height: 1.4375rem;
                            padding: 19px 30px 18px;
                        }

                        .tags-list{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 5px;
                            margin: 20px 0 0;

                            .tag{
                                display: flex;
                                align-items: center;

                                background: map-get($colors-base, bg-dark-beige);
                                border-radius: 24px;
                                padding: 2px 10px;

                                span {
                                    text-transform: uppercase;
                                }

                                .close {
                                    position: relative;
                                    margin: 0 0 0 10px;
                                    width: 10px;
                                    height: 10px;
                                    padding: 0;

                                    &:hover{
                                        .line{
                                            background: map-get($colors-buttons, tertiary-hover-color);
                                        }
                                    }

                                    .line{
                                        position: absolute;
                                        height: 1px;
                                        width: 14px;
                                        border-radius: 4px;
                                        background: map-get($colors-buttons, tertiary-color);

                                        transform-origin: center;

                                        top: 50%;
                                        left: 0;

                                        transform: rotate(45deg);

                                        transition: background-color $defaultTransitionSpeed $ease-in-out-quad;

                                        &:last-child{
                                            transform: rotate(-45deg);
                                        }
                                    }
                                }
                            }
                        }

                        .input-wrapper{
                            margin: 20px 0 10px;
                        }

                        button {
                            margin: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .submit {
            margin: 10px 0 0;
            width: 100%;
        }

        .errors-wrapper {
            margin: 30px 0 0;

            p{
                margin: 0;
                color: map-get($colors-base, error-light);

                span {
                    @include otterco-semibold();
                }
            }
        }

        @media only screen and (max-width: $md-max) {
            @include col(5);
            @include col-start(8);
            margin-left: var(--gutter-width);
            margin-right: 0;
        }

        @media only screen and (max-width: 1220px) {
            @include col(12);
            @include col-start(1);
            margin-left: 0;
            margin-right: 0;

            position: relative;
            top: 0;
            order: 1;
        }
    }

    @media only screen and (max-width: 1220px) {
        padding-bottom: 50px;
    }
}

