@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.groups-intro {
    position: relative;

    .title {
        @include col(6);
        margin-bottom: 80px;

        h2 {
            margin: 0;
        }
    }

    .description {
        @include col-start(5);
        @include col(8);

        p {
            margin: 0;
        }

        button {
            margin-top: 35px;
            display: inline-flex;
            align-items: center;
            column-gap: 8px;

            .icon-arrow-down {
                transform: rotate(180deg);
                width: 18px;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        padding-bottom: 80px;
        .title {
            @include col(12);
        }
        .description {
            @include col-start(1);
            @include col(12);
        }
    }
}
