@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;


.block-tiles{
    .title{
        .row{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 60px;
            h2{
                margin-bottom: 0;
            }
            .secondary-cta{
                white-space: nowrap;
            }
        }
    }

    .tiles-wrapper{
        .list{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 40px;
            row-gap: 60px;

            .image-wrapper{
                .icon-tile-mask{
                    aspect-ratio: unset;
                }
            }
        }

    }
    .load-more{
        margin-top: 20px;
        text-align: center;
        cursor: pointer;
    }

    @media only screen and (max-width: $sm-max){
        .load-more{
            margin-top: 60px;
        }
        .title{
            .row{
                margin-bottom: 50px;
                flex-direction: column;
                align-items: flex-start;

                h2{
                    margin-bottom: 20px;
                }
            }
        }
        .tiles-wrapper{
            .list{
                grid-template-columns: auto;
            }
        }
    }

}
