@use "config" as *;
@use "mixins" as *;
@use "functions" as *;

//
// Button styles
// -----------------------------------------------------------------------------

*.dark-green, *.light-green {
    .arrow-controls {
        border: 1px solid map-get($colors-buttons, secondary-inverse-border);
        background-color: map-get($colors-buttons, secondary-inverse-bg);

        svg{
            *{
                stroke: map-get($colors-buttons, secondary-inverse-border);
                transition: stroke 0.25s ease;
            }
        }

        &:hover {
            background-color: map-get($colors-buttons, secondary-inverse-hover-bg);

            svg {
                * {
                    stroke: map-get($colors-buttons, secondary-inverse-hover-color);
                }
            }
        }

        &.active, &:active{
            background-color: map-get($colors-buttons, secondary-inverse-active-bg);
        }
    }
}

button, a{
    background: none;
    border:none;
    outline: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;

    span[class^="icon-"] {
        margin-left: 9px;
    }

    &:disabled{
        pointer-events: none;
        filter: grayscale(1);
    }

    &.link-btn {
        >div {
            display:inline-flex !important;
            align-items: center;
            justify-content: center;
        }
    }
}

.primary-cta{
    overflow: hidden;

    @include otterco-regular();
    display:inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: rem(18);
    line-height: rem(23);
    text-transform: uppercase;
    padding: 19px 30px 18px;
    border-radius: 100px;
    border: 1px solid map-get($colors-buttons, primary-border);
    color: map-get($colors-buttons, primary-color);
    background-color: map-get($colors-buttons, primary-bg);
    text-decoration: none;
    text-align: center;

    transition: color $defaultTransitionSpeed $ease-in-out-quad, background-color $defaultTransitionSpeed $ease-in-out-quad, border $defaultTransitionSpeed $ease-in-out-quad;

    >div {
        overflow: hidden;
    }

    div {
        pointer-events: none;
    }

    span[class^="icon-"] {
        &.icon-external-link{
            svg {
                path{
                    stroke: map-get($colors-buttons, primary-color);
                }
            }
        }
        svg {
            path{
                stroke: map-get($colors-buttons, primary-color);
            }
        }
    }

    &:hover{
        text-decoration: none;
        border: 1px solid map-get($colors-buttons, primary-hover-bg);
        background-color: map-get($colors-buttons, primary-hover-bg);
    }

    &.active, &:active{
        border: 1px solid map-get($colors-buttons, primary-active-bg);
        background-color: map-get($colors-buttons, primary-active-bg);
    }

    &.small {
        font-size: rem(14);
        line-height: rem(18);
        padding: 11px 20px 10px;
    }

    @media only screen and (max-width: $sm-max) {

        &:not([type=submit]){
            font-size: rem(14);
            line-height: rem(18);
            padding: 11px 20px 10px;
        }

        &.small-mobile {
            font-size: rem(14);
            line-height: rem(18);
            padding: 11px 20px 10px;
        }

        &.big-mobile{
            font-size: rem(18);
            line-height: rem(23);
            padding: 19px 30px 18px;
        }
    }

    &.inverse {
        border: 1px solid map-get($colors-buttons, primary-inverse-bg);
        color: map-get($colors-buttons, primary-inverse-color);
        background-color: map-get($colors-buttons, primary-inverse-bg);

        span[class^="icon-"] {
            svg {
                path{
                    stroke: map-get($colors-buttons, primary-inverse-color);
                }
            }
        }

        &:hover{
            border: 1px solid map-get($colors-buttons, primary-inverse-hover-bg);
            background-color: map-get($colors-buttons, primary-inverse-hover-bg);
        }

        &.active, &:active{
            border: 1px solid map-get($colors-buttons, primary-inverse-active-bg);
            background-color: map-get($colors-buttons, primary-inverse-active-bg);
        }
    }

    &.alternate {
        border: 1px solid map-get($colors-buttons, primary-alt-bg);
        color: map-get($colors-buttons, primary-alt-color);
        background-color: map-get($colors-buttons, primary-alt-bg);

        &:hover{
            border: 1px solid map-get($colors-buttons, primary-alt-hover-bg);
            background-color: map-get($colors-buttons, primary-alt-hover-bg);
        }

        &.active, &:active{
            border: 1px solid map-get($colors-buttons, primary-alt-active-bg);
            background-color: map-get($colors-buttons, primary-alt-active-bg);
        }

        span[class^="icon-"] {
            svg {
                path{
                    stroke: map-get($colors-buttons, primary-inverse-color);
                }
            }
        }
    }

    &.highlight {
        border: 1px solid map-get($colors-buttons, primary-highlight-bg);
        color: map-get($colors-buttons, primary-highlight-color);
        background-color: map-get($colors-buttons, primary-highlight-bg);

        &:hover{
            border: 1px solid map-get($colors-buttons, primary-highlight-hover-bg);
            background-color: map-get($colors-buttons, primary-highlight-hover-bg);
        }

        &.active, &:active{
            border: 1px solid map-get($colors-buttons, primary-highlight-active-bg);
            background-color: map-get($colors-buttons, primary-highlight-active-bg);
        }
    }
}

.secondary-cta {
    overflow: hidden;

    @include otterco-regular();
    display:inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: rem(18);
    line-height: rem(23);
    text-transform: uppercase;
    padding: 19px 30px 18px;
    border-radius: 100px;
    text-decoration: none;
    border: 1px solid map-get($colors-buttons, secondary-border);
    color: map-get($colors-buttons, secondary-color);
    background-color: map-get($colors-buttons, secondary-bg);
    text-align: center;

    transition: color $defaultTransitionSpeed $ease-in-out-quad, background-color $defaultTransitionSpeed $ease-in-out-quad, border $defaultTransitionSpeed $ease-in-out-quad;

    >div {
        overflow: hidden;
    }

    div {
        pointer-events: none;
    }

    &:hover{
        text-decoration: none;
        color: map-get($colors-buttons, secondary-hover-color);
        background-color: map-get($colors-buttons, secondary-hover-bg);

        span[class^="icon-"] {
            svg {
                path{
                    stroke: map-get($colors-buttons, secondary-hover-color);
                }
            }
        }
    }

    &.active, &:active{
        color: map-get($colors-buttons, secondary-hover-color);
        background-color: map-get($colors-buttons, secondary-active-bg);
    }

    &.small {
        font-size: rem(14);
        line-height: rem(18);
        padding: 11px 20px 10px;
    }

    @media only screen and (max-width: $sm-max) {
        font-size: rem(14);
        line-height: rem(18);
        padding: 11px 20px 10px;

        &.big-mobile{
            font-size: rem(18);
            line-height: rem(23);
            padding: 19px 30px 18px;
        }
    }

    &.inverse {
        border: 1px solid map-get($colors-buttons, secondary-inverse-border);
        color: map-get($colors-buttons, secondary-inverse-color);
        background-color: map-get($colors-buttons, secondary-inverse-bg);

        &:hover{
            text-decoration: none;
            color: map-get($colors-buttons, secondary-inverse-hover-color);
            background-color: map-get($colors-buttons, secondary-inverse-hover-bg);

            span[class^="icon-"] {
                svg {
                    path{
                        stroke: map-get($colors-buttons, secondary-inverse-hover-color);
                    }
                }
            }
        }

        &.active, &:active{
            color: map-get($colors-buttons, secondary-inverse-hover-color);
            background-color: map-get($colors-buttons, secondary-inverse-active-bg);
        }

        span[class^="icon-"] {
            svg {
                path{
                    stroke: map-get($colors-buttons, secondary-inverse-color);
                }
            }
        }
    }
}

.tertiary-cta{
    overflow: hidden;

    @include otterco-regular();
    position: relative;
    display:inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: rem(14);
    line-height: rem(18.27);
    text-transform: uppercase;
    padding: 0 0 3px;
    color: map-get($colors-buttons, tertiary-color);
    text-decoration: none;

    transition: all $defaultTransitionSpeed $ease-in-out-quad;

    >div {
        overflow: hidden;
    }

    div {
        pointer-events: none;
    }

    &:after{
        content: ' ';
        background-color: map-get($colors-buttons, tertiary-color);
        height:1px;
        width: 100%;
        position: absolute;
        bottom:0;
        right:0;

        transition: all $defaultTransitionSpeed $ease-in-out-quad;
    }

    &:hover{
        color: map-get($colors-buttons, tertiary-hover-color);

        &:after {
            background-color: map-get($colors-buttons, tertiary-hover-color);
        }
    }

    &.active, &:active{
        color: map-get($colors-buttons, tertiary-active-color);

        &:after {
            background-color: map-get($colors-buttons, tertiary-active-color);
        }
    }

    &.alternate {
        color: map-get($colors-buttons, tertiary-alternate-color);

        &:after{
            background-color: map-get($colors-buttons, tertiary-alternate-color);
        }

        &:hover{
            color: map-get($colors-buttons, tertiary-alternate-hover-color);

            &:after {
                background-color: map-get($colors-buttons, tertiary-alternate-hover-color);
            }
        }

        &.active, &:active{
            color: map-get($colors-buttons, tertiary-alternate-active-color);

            &:after {
                background-color: map-get($colors-buttons, tertiary-alternate-active-color);
            }
        }
    }
}

.arrow-controls{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid map-get($colors-buttons, secondary-border);
    background-color: map-get($colors-buttons, secondary-bg);

    transition: all $defaultTransitionSpeed $ease-in-out-quad;

    span[class^=icon-].icon-arrow-down{
        width: 32px;
    }

    &.next{
        .icon-arrow-down{
            margin: 0 0 0 6px;

            svg{
                rotate: 90deg;
            }
        }
    }

    &.previous{
        .icon-arrow-down{
            margin: 0 6px 0 0;

            svg{
                rotate: -90deg;
            }
        }
    }

    svg{
        *{
            stroke: map-get($colors-buttons, secondary-border);
            transition: stroke 0.25s ease;
        }
    }

    &:hover {
        background-color: map-get($colors-buttons, secondary-hover-bg);

        svg {
            * {
                stroke: map-get($colors-buttons, secondary-hover-color);
            }
        }
    }

    &.active, &:active{
        background-color: map-get($colors-buttons, secondary-active-bg);
    }

    &:disabled{
        opacity:0.5;
    }
}

// Google reCAPTCHA Badge
.grecaptcha-badge {
    visibility: hidden;
}
