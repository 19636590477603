@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

form{
    position: relative;
    p{
        &.subtitle{
            margin-block: 0 40px;
        }
    }

    .success-message{
        margin-block: 40px 0;
        @include otterco-regular();
        color: map-get($colors-forms, success);
    }
    .error-message{
        margin-block: 40px 0;
        @include otterco-regular();
        color: map-get($colors-forms, error);
    }
}

// temporary spinner / loader.

.spinner-wrapper {
    position: absolute;
    inset: 0;
    z-index: 10;
    display: grid;
    place-items: center;
    //filter: blur(4px);


    &::after {
        content: '';
        backdrop-filter: blur(2px);
        position: absolute;
        inset: 0;
        z-index: -1;
    }

    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid map-get($colors-base, bg);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

}
