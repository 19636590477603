@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.activity-cart {
    .form-wrapper {
        @include col(6);
        @include col-start(2);
        margin-left: calc(-1 * var(--gutter-width));
        margin-right: var(--gutter-width);

        .btn-return {
            @include otterco-semibold();
            font-size: rem(16);
            line-height: rem(24);
            margin: 0 0 30px;
            text-transform: none;

            &:after {
                display: none;
            }

            .icon-arrow-down {
                flex: 0 0 auto;
                width: 14px;
                transform: rotate(-90deg);
                margin: 0 10px 0 0;
                pointer-events: none;
            }
        }

        .title-wrapper {
            margin: 0 0 30px;
        }

        .participants-wrapper {
            margin: 0 0 40px;
            width: 100%;

            .participants-error-msg {
                display: flex;
                align-items: start;
                gap: 10px;
                color: map-get($colors-base, error-light);

                .icon-warning{
                    flex: 0 0 auto;
                }
            }

            .input-wrapper {
                display: flex;
                flex-direction: row;
                align-items: start;
                gap: 0 15px;
                flex-wrap: wrap;
                width: 100%;

                &.has-error {
                    label {
                        color: map-get($colors-base, error);
                    }
                }

                label {
                    pointer-events: none;
                    position: relative;
                    top: unset;
                    left: unset;
                    transform: none;
                    margin: 0;
                    padding: 0;


                    @include otterco-light();
                    font-size: rem(20);
                    line-height: rem(30);

                    span.bold-text {
                        @include otterco-medium();
                        margin-right: 5px;
                    }
                }

                .line {
                    margin: 16px 0 0;
                    flex: 1 0 10px;
                    height: 1px;
                    background-color: map-get($colors-base, text);
                }

                .error-msg {
                    width: 100%;
                    margin: 5px 0 10px;
                }
            }
        }

        .conditions-wrapper {
            margin: 0 0 40px;

            .checkbox-error-msg {
                display: flex;
                align-items: start;
                gap: 10px;
                color: map-get($colors-base, error-light);
            }
        }

        .options-wrapper {
            margin: 0;
        }

        [type="submit"] {
            margin: 60px 0 0;
        }

        @media only screen and (max-width: $md-max) {
            @include col(7);
            @include col-start(1);
            margin-left: 0;
            margin-right: var(--gutter-width);
        }

        @media only screen and (max-width: 1220px) {
            @include col(12);
            @include col-start(1);
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 50px;

            [type="submit"] {
                display: none;
            }
        }

        @media only screen and (max-width: $xs-max) {
            .participants-wrapper {
                .input-wrapper {
                    label {
                        max-width: 52%;
                    }
                }
            }
        }
    }
}
