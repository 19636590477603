@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.parks-subnavigation{

    .links {
        @include col-start(1);
        @include col(3);
        padding: 0;

        li{
            padding: 0;

            &:hover, &.active {
                color: map-get($colors-header, subnavigation-text-hover);
            }
        }
    }

    .park-links-container {
        position: relative;

        @include col-start(4);
        @include col(5);

        .park-links{
            position: absolute;

            column-count: 2;
            column-gap: var(--gutter-width);
            column-width: auto;
            padding: 0;

            opacity: 0;
            pointer-events: none;

            transition: opacity $defaultTransitionSpeed $ease-in-out-quad;

            &.active {
                opacity: 1;
                pointer-events: auto;
                a{
                    display: inline-block;
                }
            }

            li {
                padding: 0;
                margin: 0 0 16px;

                &:before {
                    display: none;
                }

                a {
                    text-decoration: none;
                    transition: color $defaultTransitionSpeed $ease-in-out-quad;

                    &:hover {
                        color: map-get($colors-buttons, link-hover);
                    }

                    .animated-btn {
                        @include otterco-light();
                        font-size: rem(20);
                        line-height: rem(30);
                        text-transform: uppercase;

                        >div{
                            overflow: hidden;
                        }
                    }

                    span{
                        display: block;
                        @include otterco-regular();
                        font-size: rem(14);
                        line-height: rem(19.6);
                        text-transform: none;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $md-max) {

        .links {
            @include col-start(1);
            @include col(5);
        }

        .park-links-container {
            @include col-start(6);
            @include col(7);

        }

        .image-container{
            display: none;
        }
    }
}
