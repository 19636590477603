@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-cta {
    --column-list: var(--column-amount);

    padding-top: 100px;

    &.with-mountain {
        padding-top: 65px;
    }

    .centered-content {
        align-items: center;
        position: relative;
        background: map-get($colors-component, bg-block-cta);
        padding: 100px 0 120px;

        .text-wrapper {
            position: relative;
            z-index: 2;
            @include col-start(2);
            @include col(4);

            .text-content{
                margin: 0 0 40px;

                h1, h2, h3, h4, h5, h6, p, li {
                    color: map-get($colors-base, text);
                }
            }

            a {
                color: map-get($colors-buttons, secondary-color);

                &:hover{
                    color: map-get($colors-buttons, secondary-hover-color);
                }
            }
        }

        .image-content {
            position: relative;
            z-index: 2;
            height: 100%;

            @include col-start(7);
            @include col(5);

            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 800px;

            img {
                flex: 1 1 auto;
                margin: -115px 0 0;
            }
        }
    }

    @media only screen and (max-width: 1279px) {
        .centered-content {
            padding: 50px 0 70px;
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding-top: calc(100vw - 135px);

        &.with-mountain {
            padding-top: 80px;
        }

        .centered-content {
            align-items: unset;
            padding: 0 20px 50px;

            .text-wrapper {
                order: 2;
                @include col-start(1);
                @include col(12);

                .text-content{
                    margin: 0 0 30px;
                }
            }

            .image-content {
                height: 170px;
                order: 1;
                @include col-start(1);
                @include col(12);

                img {
                    position: absolute;
                    bottom: 30px;
                    margin: -200px 0 0;
                }
            }
        }

    }

}
