@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.anchor-btn{
    position: fixed;
    bottom: 100px;
    left: 40px;
    z-index: 99;
    background: none;
    padding: 0;
    margin: 0;
    overflow: visible;
    opacity: 1;
    transition: all 200ms ease-in-out;

    &.hide-btn{
        opacity: 0;
        pointer-events: none;
    }

    .link-btn{
        .icon-arrow-down{
            svg{
                transform: rotate(180deg);
            }
        }
    }
}
