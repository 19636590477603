@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.primary-navigation {
    z-index: 3;
    border-top: 1px solid map-get($colors-header, border);
    transition: background-color $defaultTransitionSpeed $ease-in-out-quad, top $defaultTransitionSpeed*2 $ease-in-out-quad;

    &.fixed {
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        right: 0;

        background: map-get($colors-header, bg-fixed);

        &~ .subnavigation {
            padding: calc(75px + 100px) var(--gutter-width) 0;

            &:before{
                height: calc(75px + 100px);
            }
        }

        &.hide-up, &.force-hide {
            top: -101px;
        }
    }

    .centered-content {
        .primary-navigation-wrapper {
            list-style: none;
            flex-direction: row;
            align-items: center;

            height: 100px;

            margin: 0;
            padding: 0;

            &.desktop-only {
                display: flex;
            }

            &.mobile-only {
                display: none;
            }

            >li {
                display: flex;
                align-items: center;

                padding: 0;
                margin: 0 40px 0 0;

                &:first-child {
                    margin: 0 auto 0 0;
                }

                &:last-child {
                    margin: 0;
                }

                &:before {
                    display: none;
                }

                &.has-subnavigation {
                    position: unset;
                    >span{
                        cursor: pointer;
                    }
                    &:hover{
                        >a, >span {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                        }
                    }
                }

                .logo, .primary-cta {
                    &:before{
                        display: none;
                    }
                }

                .special-nav-link {
                    position: relative;
                    transition: color $defaultTransitionSpeed $ease-in-out-quad;

                    @include otterco-medium();
                    font-size: rem(16);
                    line-height: rem(24);
                    letter-spacing: 0.05rem;
                    text-transform: uppercase;
                    text-decoration: none;

                    color: map-get($colors-header, special-link-color);

                    cursor: pointer;

                    &:hover {
                        color: map-get($colors-header, special-link-color-hover);
                    }
                }

                >a:not(.primary-cta):not(.logo), >span {
                    position: relative;
                    transition: color $defaultTransitionSpeed $ease-in-out-quad;

                    @include otterco-medium();
                    font-size: rem(16);
                    line-height: rem(24);
                    letter-spacing: 0.05rem;
                    text-transform: uppercase;
                    text-decoration: none;

                    cursor: pointer;

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: -40px;
                        left: 50%;
                        right: 50%;

                        height: 3px;
                        background-color: map-get($colors-base, text);

                        transition: all $defaultTransitionSpeed $ease-in-out-quad;
                    }

                    &:hover {
                        &:before {
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }



    @media only screen and (max-width: $sm-max) {
        border-top: none;

        &.opened-mobile-menu {
            .icon-mobile-menu{
                line{
                    &:nth-child(1){
                        transform: rotate(45deg) translate(5px, 0px);
                    }
                    &:nth-child(2){
                        opacity: 0;
                    }
                    &:nth-child(3){
                        transform: rotate(-45deg) translate(-13px, -4px);
                    }
                }
            }
        }

        .centered-content {
            .primary-navigation-wrapper {
                height: auto;
                padding: 20px 0;

                li {
                    margin: 0 20px 0 0;

                    &:first-child {
                        margin: 0 auto 0 0;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }

                &.desktop-only {
                    display: none;
                }
                &.mobile-only {
                    display: flex;
                }

                .icon-logo{
                    width: 124px;
                }
            }
        }
    }
}
