@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.load-more-button{
    display:flex;
    justify-content: center;

    &.light{
        button{
            color: map-get($colors-base, bg-white);

            &:hover{
                &:after{
                    background-color: map-get($colors-base, bg-white);
                }
            }
        }
    }
    @media only screen and (max-width: $xs-max){
        &.light{
            margin-top: 20px;
            button{
                &:after{
                    background-color: map-get($colors-base, bg-white);
                }
            }
        }
    }
}
