@import "../../../config";
@import "../../../mixins";
@import "../../../functions";

.overlay{
    position: fixed;
    top:0;
    z-index:999;
    width: 100vw;
    height: 100vh;
    opacity:0;
    pointer-events: none;
    background: transparent;

    &.active{
        pointer-events: auto;
    }

    .backdrop{
        content: ' ';
        position: fixed;
        inset: 0;
        background-color: rgba(0,0,0,0.01);
    }

    .wrapper {
        overflow-y: scroll;
        box-shadow: 0 0 20px rgba(0,0,0,0.2);

        .close{
            position: absolute;
            top: 15px;
            right: 15px;
            transition: opacity ease 250ms;
            &:hover{
                opacity: 0.9;
                transition: opacity ease 250ms;
            }
            span{
                width: 17px;
            }
        }
    }
}
