@import "../../../config";
@import "../../../mixins";
@import "../../../functions";

.overlay{
    position: fixed;
    top:0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    opacity:0;
    pointer-events: none;
    transition: none;
    background: transparent;


    &.active{
        pointer-events: auto;
    }

    .backdrop{
        content: ' ';
        position: fixed;
        inset: 0;
        background-color: rgba(0,0,0,0.01);
    }
}

.switch-site-overlay{
    .wrapper{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: var(--text-color);
        width: 645px;
        max-height: 100vh;
        padding: 70px;
        margin:0 auto;
        background: map-get($colors-overlay, background);
        overflow-y: auto;

        .close{
            position: absolute;
            top: 15px;
            right: 15px;
            transition: opacity ease 250ms;
            &:hover{
                opacity: 0.9;
                transition: opacity ease 250ms;
            }
            span{
                width: 17px;
            }
        }

        .icon-logo-treetop-left, .icon-logo-arbraska{
            display: block;
            width: 347px;
        }
        p{
            margin-block: 30px 40px;
        }
    }

    @media only screen and (max-width: $sm-max) {

    }

    @media only screen and (max-width: $xs-max) {
        .wrapper{
            max-width: 645px;
            width: 85%;
            padding: 30px;
            .icon-logo-treetop-left, .icon-logo-arbraska{
                width: 90%;
                max-width: 300px;
            }
        }
    }
}
