@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.accordion {
    @include grid(var(--column-amount), var(--gutter-width));
    border-bottom: 1px solid var(--border-color);
    padding: 40px 0;

    &:last-child{
        border-bottom: none;
    }

    &:hover {
        cursor: pointer;
    }

    .centered-content{
        width: 100% !important;
    }

    &.active, &.is-opening {
        .centered-content{
            .accordion-trigger {
                svg {
                    rotate: 0deg;
                }
            }
        }
    }

    .centered-content {
        width: 100%;

        .accordion-content-wrapper {
            --column-accordion-wrapper: 12;
            @include grid(var(--column-accordion-wrapper), var(--gutter-width));
            @include col(var(--column-accordion-wrapper));
            @include col-start(1);
            align-items: flex-start;
        }

        .accordion-trigger {
            @include col(2);
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0;
            margin: 5px 0 0;


            svg {
                rotate: 180deg;
                transition: rotate 250ms ease-in-out;

                path {
                    stroke: var(--svg-color);
                }
            }
        }

        .accordion-title, .accordion-content {
            @include col(10);
            @include col-start(1);
        }

        .accordion-title {
            text-transform: uppercase;
            font-size: rem(28);
        }

        .accordion-content {
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s ease;

            > *:first-child {
                padding-top: 20px;
            }
            a:not(.primary-cta):not(.secondary-cta):not(.tertiary-cta){
                color: var(--text-color);
            }

            .franchise-park{
                p{
                    margin-bottom: 15px;
                }
            }
        }

        p {
            margin: 0;
        }
    }

    @media only screen and (max-width: $sm-max){
        > .centered-content {
            width: 100%;
        }
    }


    @media only screen and (max-width: $xs-max) {
        padding: 40px 0;

        &.always_open{
            .accordion-trigger {
                display:none;
            }

            .centered-content{
                .accordion-title {
                    @include col(12);
                }

                .accordion-content {
                    @include col(12);
                }
            }
        }

        .centered-content {
            width: 100%;
            .accordion-content-wrapper {
                --column-accordion-wrapper: 12;
                @include col-start(auto);
            }

            .accordion-trigger {
                @include col(1);
                @include col-start(12);
                grid-row-start: 1;
                justify-content: flex-end;
            }

            .accordion-title {
                //@include text-l-semibold();
                @include col(11);
                @include col-start(1);
                line-height: 1.4;
            }

            .accordion-content {
                @include col(var(--column-accordion-wrapper));
                @include col-start(1);
            }
        }
    }
}
