@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

// Select base theme
.select-wrapper {
    --border-color: #{map-get($colors-forms, border)};
    --svg-color: #{map-get($colors-forms, text)};
    --text-color:#{map-get($colors-forms, text)};

    color: var(--text-color);

    display: inline-block;
    position: relative;
    margin-bottom: 20px;

    opacity: 0;
    transition: opacity $defaultTransitionSpeed $ease-in-out-quad;

    &:not(.smaller){
        width: 100%;
    }

    &.big{
        margin-bottom: 0;
        width: unset;
        vertical-align: top;

        &.dark{
            .choices{
                .choices__placeholder {
                    color: map_get($colors-select-big, selected-dark) !important;
                }
                .choices__inner {
                    border-bottom-color: map_get($colors-select-big, border-dark);

                    .choices__list {
                        &--single {
                            color: map_get($colors-select-big, border-dark);

                            .choices__item{
                                color: map_get($colors-select-big, border-dark);
                            }
                        }
                    }
                }
                .choices__list--dropdown {
                    border: none;

                    > .choices__list {
                        max-height: 0;
                    }

                    &.is-active {
                        > .choices__list {
                            max-height: 400px;
                            @media only screen and (max-width: $xs-max){
                                padding-block: 25px;
                                max-height: calc(100dvh - 50px);
                            }
                        }
                    }
                }
            }
            span.icon-arrow-down {
                pointer-events: none;
                svg {
                    path {
                        stroke: map_get($colors-select-big, svg-dark);
                    }
                }
            }
        }

        label{
            display: none;
        }

        .choices{
            &.is-open, &.is-focus {
                .choices__inner {
                    background: map-get($colors-select-big, bg-opened);
                    border-radius: 40px 40px 0 0;
                    padding: 25px 100px 10px 30px;

                    .choices__list{
                        &.choices__list--single{
                            .choices__item{
                                color: map_get($colors-select-big, selected-opened);
                            }
                        }
                    }
                }
                ~ span.icon-arrow-down {

                    svg {
                        transform: rotate(0deg) translateY(-25%);
                        path {
                            stroke: map_get($colors-select-big, svg-white);
                        }
                    }
                }
            }

            .choices__placeholder {
                color: map_get($colors-select-big, selected-light) !important;
                display: block;
                opacity: 1;
            }

            .choices__inner {
                padding: 0 100px 10px 0;
                border-radius: 0;
                border-top: none;
                border-left: none;
                border-right: none;
                //border-bottom-color: map_get($colors-select-big, border-light);
                border-bottom-color: map_get($colors-select-big, border-white);
                background: transparent;

                .choices__list {
                    &--single {
                        padding: 0;
                        .choices__item--selectable {
                            color: map_get($colors-select-big, selected-light);
                        }
                    }

                    .choices__item {
                        @include adieu();
                        font-weight: 400;
                        font-size: vw(50px);
                        line-height: 100%;
                    }
                }

                .choices__list--dropdown {
                    > .choices__list {
                        .choices__item {
                            background: map-get($colors-select-big, bg-opened);
                            color: map_get($colors-select-big, option);

                            &:hover, &:active, &:focus, &.is-highlighted {
                                background: map-get($colors-select-big, bg-opened);
                                color: map_get($colors-select-big, selected-opened);
                                @include adieu();
                            }
                        }
                    }
                }
            }

            .choices__list--dropdown {
                z-index: 99;
                border: none;
                border-radius: 0 0 40px 40px;
                padding-bottom: 30px;
                background: map-get($colors-select-big, bg-opened);

                &.is-active {
                    opacity: 1;
                    > .choices__list {
                        max-height: 400px;
                        @media only screen and (max-width: $xs-max){
                            padding-block: 25px;
                            max-height: calc(100dvh - 50px);
                        }
                    }
                }

                > .choices__list {
                    color: map_get($colors-select-big, option);
                    max-height: 0;

                    .choices__item {
                        @include adieu();
                        font-weight: 400;
                        font-size: rem(36);
                        line-height: rem(36);
                        background: map-get($colors-select-big, bg-opened);
                        color: map_get($colors-select-big, option);
                        word-break: break-word;

                        &:hover, &:active, &:focus, &.is-highlighted {
                            background: map-get($colors-select-big, bg-opened);
                            color: map_get($colors-select-big, selected-opened);
                            @include adieu();
                        }
                    }
                }
            }
        }
        span.icon-arrow-down {
            pointer-events: none;
            width: 66px !important;
            top: 50%;
            transform: translateY(-50%);
            svg {
                transform: rotate(180deg);
                path {
                    stroke-width: 0.5px;
                    stroke: map_get($colors-select-big, svg-white);
                }
            }
        }
    }

    &.primary{
        label{
            color: map_get($colors-forms, label);
        }
        .choices{
            &.is-open, &.is-focus {
                .choices__inner {
                    //border-radius: 24px 24px 0 0;
                }
            }
            .choices__inner {
                border-radius: 24px 24px;
                color: map_get($colors-forms, label);
                background: map-get($colors-forms, background);
                min-height: 50px;
                padding: 0 0 0 20px;

                .choices__list {
                        &--single {
                            padding: 0;
                        }
                    .choices__item{
                        @include otterco-light();
                        font-size: rem(16);
                        color: map_get($colors-forms, label);
                        word-break: keep-all;
                    }
                }
            }
            .choices__list--dropdown {
                > .choices__list {
                    .choices__item {
                        @include otterco-light();
                        font-size: rem(16);
                        padding-left: 20px;
                    }
                }
            }
        }
        span.icon-arrow-down {
            width: 12px !important;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            svg {
                path {
                    stroke: var(--svg-color);
                    stroke-width: 2px;
                }
            }
        }
    }

    .bg-light-beige {
        .choices{
            .choices__inner {
                background: map-get($colors-forms, background-light-beige);

            }
        }
    }

    .absolute-wrapper {
        &.has-error {

            .choices {
                .choices__inner {
                    border: 1px solid map-get($colors-forms, error);
                    color: map-get($colors-forms, error);
                }
            }

            label {
                color: map-get($colors-forms, error);

                & + .error-msg {
                    color: map-get($colors-forms, error);
                }
            }

            span.icon-arrow-down {
                svg {
                    path {
                        stroke: map_get($colors-forms, error);
                    }
                }
            }
        }
    }

    label {
        cursor: pointer;
        position: absolute;
        left: 33px;
        //left: 30px;
        top: 31px;
        transform: translateY(-50%);
        font-size: rem(14);
        line-height: rem(20);
        @include otterco-regular();
    }

    .error-msg {
        margin: 15px 0 0 35px;
        font-size: rem(13);
        line-height: rem(14);
        @include otterco-regular();
        letter-spacing: 0;
        color: map-get($colors-forms, error);
        display: flex;
        align-items: start;
        gap: 10px;

        .icon-warning{
            flex: 0 0 auto;
        }
    }

    .choices {
        margin-bottom: 0;

        &.is-disabled{
            cursor: not-allowed;
            & ~ label{
                opacity: 0.8;
                cursor: not-allowed;
            }
            .choices__inner{
                background-color: map-get($colors-forms, disabled);
            }
        }

        & ~ label{
            color: map-get($colors-forms, label-darker)
        }

        &.has-value {

            & ~ label {
                opacity: 0;
                display: none;
            }

        }

        &[data-type*=select-one]::after {
            display: none;
        }

        &.is-open, &.is-focus {
            .choices__inner {
                border-radius: 40px 40px;
                transition: all $defaultTransitionSpeed $ease-in-out-quad;

                span.icon-arrow-down {

                        transform: rotate(0deg);

                }
            }

            & ~ span.icon-arrow-down, & + span.icon-arrow-down {
                transform: rotate(0deg);
                transition: all $defaultTransitionSpeed $ease-in-out-quad;
            }

            .choices__list--dropdown {
                opacity: 1;
                border-color: map-get($colors-forms, border);
            }
        }

        .choices__placeholder {
            display: none;
        }

        .choices__inner {
            border-radius: 40px 40px;
            z-index: 99;
            @include otterco-regular();
            font-size: rem(14);
            color: map_get($colors-forms, label-darker);
            min-height: 60px;
            padding: 4px 50px 5px 22px;
            display: flex;
            align-items: center;
            border: 1px solid map-get($colors-forms, border);
            transition: all $defaultTransitionSpeed $ease-in-out-quad;
            cursor: pointer;
            background: transparent;

            input[type="search"] {
                display: none;
            }

            .choices__list {
                &--single {
                    padding: 5px 16px 0 9px;
                    .choices__button{
                        display: none;
                    }
                }

                .choices__item {
                    border: none;
                    background: transparent;
                    @include otterco-regular();
                    font-size: rem(14);
                    //color: var(--text-color);
                    color: map_get($colors-forms, label-darker);
                    margin-right: 0;
                    margin-bottom: 0;
                    word-break: keep-all;
                }

                &--multiple {
                    .choices__item {
                        padding: 6px 13px 6px 20px;
                        background-color: map_get($colors-forms, background);
                        color: map_get($colors-forms, label);
                        margin: 2px;
                        word-break: keep-all;
                    }
                }
            }

            .choices__input {
                padding: 5px 0 4px 5px;
                margin-bottom: 0;
            }
        }

        .choices__list--dropdown {
            border-radius: 40px 40px;
            @include otterco-light();
            padding-block: 15px;
            //border-top: none;
            opacity: 0;
            border: 1px solid map-get($colors-forms, border);
            transition: opacity $defaultTransitionSpeed $ease-in-out-quad;

            > .choices__list {
                max-height: 0;
                transition: all $defaultTransitionSpeed $ease-in-out-quad;

                .choices__item {
                    padding: 12px 20px 11px 30px;
                    //border-bottom: 1px solid map-get($colors-forms, border-active);
                    background: map_get($colors-forms, background-light);
                    //color: map-get($colors-forms, label-dark);
                    color: var(--text-color);
                    transition: all $defaultTransitionSpeed $ease-in-out-quad;
                    box-sizing: border-box;
                    word-break: keep-all;

                    &:after {
                        display: none;
                    }

                    &:hover, &:active, &:focus, &.is-highlighted {
                        background: map-get($colors-forms, background-hover);
                        color: map_get($colors-forms, label-hover-dark);
                        @include otterco-medium();
                    }
                }
            }

            &.is-active {
                > .choices__list {
                    max-height: 190px;
                    transition: all $defaultTransitionSpeed $ease-in-out-quad;
                }
            }

        }
    }

    span.icon-arrow-down {
        position: absolute;
        transition: all $defaultTransitionSpeed $ease-in-out-quad;
        right: 20px;
        //top: 50%;
        //@TODO Need to review has-value and select-wrapper parent container. Conflict with parent position relative and hiding label if select has value
        top: 25px;
        transform: rotate(180deg);
        //transform: translateY(-50%) rotate(180deg);
        width: 17px !important;

        svg {
            transition: transform $defaultTransitionSpeed $ease-in-out-quad;
            stroke-width: 1.5px;

            path {
                stroke: map_get($colors-forms, label-darker);
            }
        }
    }
}

@media only screen and (max-width: $sm-max) {
    .select-wrapper {
        &.big{
            .choices {
                .choices__inner {
                    padding: 2px 100px 10px 0;
                    min-height: 40px;
                }
            }
            span.icon-arrow-down {
                width: 44px !important;
            }
        }
    }
}

@media only screen and (max-width: $xs-max) {
    .select-wrapper {
        margin-bottom: 10px;

        &.big{
            width: 100%;
            margin-bottom: 0;

            .choices {
                .choices__list--single {
                    .choices__item {
                        width: 110%;
                    }
                }
                &.is-open, &.is-focus {
                    .choices__inner {
                        background: inherit;
                        border-radius: 0;
                        padding: 5px 100px 10px 0;

                        .choices__list{
                            &.choices__list--single{
                                .choices__item{
                                    color: map_get($colors-select-big, selected-dark);
                                }
                            }
                        }
                    }
                    ~ span.icon-arrow-down {
                        svg {
                            path {
                                stroke: map_get($colors-select-big, svg-dark);
                            }
                        }
                    }
                }

                .choices__inner{
                    .choices__list{
                        .choices__item {
                            font-size: vw(32px, true);
                        }
                    }
                }

                .choices__list--dropdown {
                    position: fixed;
                    inset: 0;
                    width: 100vw;
                    height: calc(100dvh + 1px);
                    border-radius: 0;
                    overflow: scroll;
                    z-index: 998;
                    opacity: 0;
                    transition: opacity $defaultTransitionSpeed $ease-in-out-quad;
                }
            }
        }

        .choices__list{
            &.choices__list--dropdown{
                &.is-active{
                    opacity:1;
                }
            }
        }

        .smaller{
            .choices {
                &.is-open, &.is-focus {
                    .choices__inner {
                        border-radius: 25px 25px 0 0;
                    }
                }
                .choices__inner {
                    min-height: 40px;

                    //.choxices__list {
                    //    width: calc(100% - 45px);
                    //}
                }
            }

            label {
                top: 21px;
                left: 20px;
            }

            span.icon-arrow-down {
                top: 16px;
            }
        }

        &.primary{
            .choices{
                .choices__inner{
                    margin-top: 2px;
                }
                &.is-open{
                    .choices__inner{
                        background-color: map-get($colors-forms, background-active);
                    }
                    + span{
                        &.icon-arrow-down{
                            transform: translateY(-50%);
                        }
                    }
                }

            }
            span{
                &.icon-arrow-down{
                    svg{
                        path{
                            stroke: map-get($colors-forms, background-light);
                        }
                    }
                }
            }
        }

    }
}
