@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;


.block-instagram {
    &.with-mountain {
        padding-top: 100px;
    }

    .swiper{
        // currently required
        overflow: unset;
    }

    &.home-insta{
        padding-bottom: 0px;
    }


    .title{
        margin-bottom: 80px;
        h2{
            margin: 0;
        }
        .subtitle{
            display: flex;
            column-gap: 20px;
            align-items: center;
            margin-block: 30px 0;
            text-transform: uppercase;
            .instagram-icon{
                display: flex;
                align-items: center;
            }
        }

    }
    .tile {
        .image-wrapper {
            aspect-ratio: 514/568;
            border-radius: 5px;
            margin-bottom: 10px;
            img {
                aspect-ratio: 514/568;
                width: 100%;
            }
            .icon-tile-mask{
                svg{
                    aspect-ratio: 514/568;
                }
            }
        }
    }
    @media only screen and (max-width: $sm-max){
        &.home-insta{
            padding-bottom: 50px;
        }
        .title{
            margin-bottom: 40px;
            .subtitle{
                font-size: rem(16);
                line-height: rem(20);
                white-space: nowrap;
                letter-spacing: 0.5px;
                column-gap: 10px;

            }
        }
        .centered-content{
            .swiper.swiper-horizontal{
                .swiper-arrows{
                    display: none;
                }
            }
        }



    }
}

