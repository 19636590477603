@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.payment-form-wrapper {
    p.error-msg {
        display: flex;
        align-items: start;
        gap: 10px;
        margin: 10px 0 0;
        font-size: rem(14);
        line-height: rem(20);
        color: map-get($colors-base, error);

        .icon-warning{
            flex: 0 0 auto;
        }
    }

    .accordion {
        border-color: map-get($colors-base, bg-dark-beige);

        &:last-child{
            .accordion-content {
                padding: 0;
            }
        }

        &.active, &.is-opening {
            .accordion-title{
                h6 {
                    font-weight: 400;
                    font-size: rem(32);
                    line-height: rem(32);

                    span {
                        background-color: map-get($colors-base, light-text);
                        color: map-get($colors-base, bg-beige);
                    }
                }
            }
        }

        .accordion-trigger{
            .accordion-trigger-content {
                display: flex;
                align-items: center;

                position: relative;
                font-size: rem(14);
                line-height: rem(18);
                font-weight: 400;
                color: map-get($colors-base, light-text);
                text-transform: uppercase;
                transition: color $defaultTransitionSpeed $ease-in-out-quad;

                &:hover {
                    color: map-get($colors-base, text);

                    &:after{
                        background-color: map-get($colors-base, text);
                    }

                    .icon-menu {
                        transition: none;

                        svg {
                            transition: none;

                            rect {
                                fill: map-get($colors-base, text);
                            }
                        }
                    }
                }

                .icon-menu {
                    width: 10px;
                    margin-top: -2px;

                    svg {
                        rect {
                            fill: map-get($colors-base, light-text);
                            transition: fill $defaultTransitionSpeed $ease-in-out-quad;
                        }
                    }
                }

                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: map-get($colors-base, border);
                    transition: background-color $defaultTransitionSpeed $ease-in-out-quad;
                }
            }
        }

        .accordion-title{
            pointer-events: none;

            h6 {
                display: flex;
                align-items: center;
                gap: 20px;
                text-transform: none;

                transition: all $defaultTransitionSpeed $ease-in-out-quad;

                margin: 0;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    flex: 0 0 auto;
                    width: 37px;
                    height: 37px;
                    border: 1px solid map-get($colors-base, border);
                    border-radius: 50%;

                    @include otterco-semibold();
                    font-size: rem(18);
                    line-height: rem(21.78);
                    color: map-get($colors-base, light-text);

                    transition: all $defaultTransitionSpeed $ease-in-out-quad;
                }
            }

            .display-infos {
                margin: 4px 0 0 57px;

                p {
                    text-transform: none;
                }
            }
        }

        .accordion-content {
            @include col(12);
            padding: 0 0 0 57px;

            .informations-input-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin: 0 0 10px;

                .input-wrapper{
                    margin: 0;

                    &:has(.small) {
                        width: calc(50% - 10px);

                    }
                }
            }

            .wysiwyg {
                margin: 0 0 30px;
            }

            .conditions-form {
                [type="submit"] {
                    margin: 20px 0 0;
                }
            }

            .card-form-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin: 30px 0 0 0;

                .card-group {
                    padding: 22px;
                    border-radius: 30px;
                    border: 1px solid grey;
                    transition: all $defaultTransitionSpeed $ease-in-out-quad;

                    &.is-open {
                        background: map-get($colors-base, bg);
                        border-color: map-get($colors-base, bg);
                    }

                    .radio-wrapper {
                        margin: 0;
                    }

                    .content {
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.2s ease-in-out;

                        .sq-card-iframe-container{
                            margin-top: 22px;
                        }

                        p {
                            margin-top: 22px;
                        }

                        #gift-button {
                            margin-top: 22px;
                        }
                    }
                }
            }
        }
    }


    @media only screen and (max-width: $sm-max) {
        .accordion {
            .accordion-content {
                padding: 0;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .accordion {
            &.always_open{
                .accordion-title {
                    @include col(10);
                }
                .accordion-trigger{
                    display: block;
                }
            }

            .accordion-content {
                .informations-input-wrapper {
                    .input-wrapper {
                        &:has(.small) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
