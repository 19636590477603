@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-text-big {
    --column-list: var(--column-amount);
    overflow: hidden;
    padding: 100px 0;

    &.with-mountain{
        padding-top: 100px;
    }

    p{
        @include adieu();
        font-size: rem(110);
        line-height: rem(99);
        font-weight: 400;
        margin: 0;
    }

    @media only screen and (max-width: $xs-max) {
        padding: 80px 0;

        &.with-mountain{
            padding-top: 80px;
        }

        p{
            font-size: rem(45);
            line-height: rem(45);
        }
    }
}
