@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.checkout-header.section {
    position: relative;
    background: map-get($colors-base, bg-beige);
    border-bottom: 1px solid map-get($colors-base, bg-dark-beige);

    &.light {
        background: map-get($colors-base, bg-light-beige);
    }

    .centered-content {
        .header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            padding: 38.5px 0;

            .logo {
                .icon-logo-arbraska {
                    margin: 0;
                }
            }
            .paiement {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                p{
                    @include otterco-regular();
                    margin: 0 10px 0 0;
                }

                .paiement-icons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            .header-wrapper {
                padding: 17px 0;

                .paiement {
                    flex-direction: column;
                    align-items: end;
                    gap: 0;

                    p{
                        margin: 0;
                    }
                }
            }
        }
    }
}
