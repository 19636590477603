@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.input-number-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .btn-sub, .btn-add {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        padding: 0;
        margin: 0;

        color: map-get($colors-buttons, primary-color);
        background-color: map-get($colors-buttons, primary-bg);
        transition: background-color $defaultTransitionSpeed $ease-in-out-quad;

        &:hover{
            background-color: map-get($colors-buttons, primary-hover-bg);
        }

        &.disabled{
            pointer-events: none;
            border: 1px solid map-get($colors-buttons, primary-bg);
            color: map-get($colors-buttons, primary-inverse-color);
            background-color: map-get($colors-base, bg-beige);
        }
    }

    .number {
        margin: 0;
        width: 20px;
        text-align: center;
        color: map-get($colors-base, text);
    }
}
