@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-tabel-icon-text {
    --column-list: var(--column-amount);
    --border-color: #{map-get($colors-component, dark)};
    padding: 100px 0;

    &.with-mountain {
        padding: 100px 0 0;
    }

    &.dark-green, &.light-green {
        --border-color: #{map-get($colors-component, light)};
    }

    overflow: hidden;

    .centered-content {
        .title-content {
            width: 100%;
            border-bottom: 1px solid var(--border-color);

            p{
                text-transform: uppercase;
                margin: 0 0 20px;
            }
        }

        .icons-content {
            @include col-start(5);
            @include col(8);

            .icon-single{
                --column-table-icon-wrapper: 8;
                @include grid(var(--column-table-icon-wrapper), var(--gutter-width));
                @include col(var(--column-table-icon-wrapper));
                @include col-start(1);

                padding: 60px 0;
                border-bottom: 1px solid var(--border-color);

                &:last-child{
                    border-bottom: none;
                }

                .icon-image-content{
                    @include col-start(1);
                    @include col(2);

                    .icon-image{
                        max-width: 100px;
                    }
                }
                .icon-text-content{
                    @include col-start(3);
                    @include col(6);

                    .icon-title{
                        text-transform: uppercase;
                        margin: 0 0 10px;
                    }
                    .icon-description{
                        margin: 0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content {
            .icons-content {
                @include col-start(3);
                @include col(10);
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding: 80px 0;

        &.with-mountain{
            padding-top: 80px;
        }

        .centered-content {
            .icons-content {
                @include col-start(1);
                @include col(12);

                .icon-single{
                    --column-table-icon-wrapper: 12;
                    padding: 30px 0;

                    .icon-image-content{
                        @include col-start(1);
                        @include col(12);
                        margin: 0 0 20px;
                    }

                    .icon-text-content{
                        @include col-start(1);
                        @include col(12);
                    }
                }
            }
        }

    }

}
