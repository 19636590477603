@use "config" as *;
@use "mixins" as *;
@use "functions" as *;

//
// Root styles
// -----------------------------------------------------------------------------

html {
    --column-amount: 12;
    --gutter-width: 40px;

    //Retire le smooth scroll lors des transitions de page avec barba JS pour pas qu'onv oit la page scrolltop lentement pendant la transition.
    &.in-transition{
        scroll-behavior: auto;
    }

    &.scroll-padding-top{
        scroll-padding-top: 125px;
    }

    .ajax-loading{
        filter: blur(4px);
    }

    //classe utilisé lors d'ouverture d'overlays pour éviter le scroll sur le body et dans l'overlay en même temps.
    &.no-scroll{
        overflow: hidden;
    }

    &.lenis {
        height: auto;
    }

    @media only screen and (max-width: $md-max) {
        --column-amount: 12;
        --gutter-width: #{$gutter-width-md};
    }

    @media only screen and (max-width: $sm-max) {
        --column-amount: 12;
        --gutter-width: #{$gutter-width-sm};
    }

    @media only screen and (max-width: $xs-max) {
        --column-amount: 12;
        --gutter-width: #{$gutter-width-xs};
    }
}

// Lenis (smoothscroll)
.lenis.lenis-smooth {
    scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}
.lenis.lenis-stopped {
    overflow: hidden;
}
.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

//CookieYes
.cky-btn-revisit-wrapper{
    z-index: 100 !important;
}

body {
    // Changed for testing purpose - to validate
    background: map-get($colors-base, bg-light-beige);


    color: map-get($colors-base, text);
    @include otterco-light();

    &.preload *{
        animation-duration: 0s !important;
        -webkit-animation-duration: 0s !important;
        transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
    }

    &:has(#site-ctn[class^="park-"]){
        background: map-get($colors-base, bg-dark-green);
    }
}

#page-transition {
    position: fixed;
    top: 0;
    z-index: 999;
    transform: translateY(calc(100vh + 280px));
    transition: transform 0.5s ease;

    .pt-mountain {
        position: absolute;
        top: -280px;
        height: 100vh;

        .icon-spacer {
            margin-top: 0;
            display: block;

            &:last-of-type svg {
                margin-top: -1px;
                transform: rotateX(180deg);
            }
        }

        .pt-mountain-body {
            margin: -1px 0 0 0;
            padding: 0;
            height: 100%;
        }
    }
}

*.dark-green, *.light-green {
    color: map-get($colors-base, text-invert);

    h1, .like-h1,
    h2, .like-h2,
    h3, .like-h3,
    h4, .like-h4,
    h5, .like-h5,
    h6, .like-h6,
    p, li,
    a {
        color: map-get($colors-base, text-invert);
    }

    ul {
        li {
            &:before {
                background: map-get($colors-base, text-invert);
            }
        }
    }
}

* {
    box-sizing: border-box;
}




//
// Heading styles
// -----------------------------------------------------------------------------

h1, .like-h1, .like-h3.like-h1 {
    @include adieu();
    font-weight: 400;
    font-size: vw(100px);
    line-height: 100%;
    margin:0 0 20px;

    &.bigger {
        font-size: vw(140px, false, 0px, 6.9444444444vw);

        @media only screen and (max-width: $xs-max) {
            font-size: vw(50px, true);
        }
    }

    strong {
        @include adieu();
        font-weight: 400;
        font-size: vw(100px);
        line-height: 100%;
        margin:0 0 20px;

        @media only screen and (max-width: $xs-max) {
            font-size: vw(40px, true);
        }
    }

    @media only screen and (max-width: $xs-max) {
        font-size: vw(40px, true);
    }
}

h2, .like-h2 {
    @include adieu();
    font-weight: 400;
    font-size: vw(70px);
    line-height: 100%;
    margin:0 0 20px;

    @media only screen and (max-width: $xs-max) {
        font-size: vw(36px, true);
    }

    strong {
        @include adieu();
        font-weight: 400;
        font-size: vw(70px);
        line-height: 100%;
        margin:0 0 20px;

        @media only screen and (max-width: $xs-max) {
            font-size: vw(36px, true);
        }
    }
}


h3, .like-h3 {
    @include adieu();
    font-weight: 400;
    font-size: vw(50px);
    line-height: 100%;
    margin:0 0 20px;

    @media only screen and (max-width: $xs-max) {
        font-size: vw(32px, true);
    }

    strong {
        @include adieu();
        font-weight: 400;
        font-size: vw(50px);
        line-height: 100%;
        margin:0 0 20px;

        @media only screen and (max-width: $xs-max) {
            font-size: vw(32px, true);
        }
    }
}

h4, .like-h4 {
    @include adieu();
    font-weight: 400;
    font-size: vw(36px);
    line-height: 100%;
    margin:0 0 20px;

    @media only screen and (max-width: $xs-max) {
        font-size: vw(36px, true);
    }
}

h5, .like-h5 {
    @include adieu();
    font-weight: 400;
    font-size: vw(32px);
    line-height: 100%;
    margin:0 0 20px;

    @media only screen and (max-width: $xs-max) {
        font-size: vw(26px, true);
    }

    strong {
        @include adieu();
        font-weight: 400;
        font-size: vw(32px);
        line-height: 100%;
        margin:0 0 20px;

        @media only screen and (max-width: $xs-max) {
            font-size: vw(26px, true);
        }
    }
}

h6, .like-h6 {
    @include adieu();
    font-weight: 400;
    font-size: vw(22px);
    line-height: 100%;
    margin:0 0 20px;

    @media only screen and (max-width: $xs-max) {
        font-size: vw(16px, true);
    }

    strong {
        @include adieu();
        font-weight: 400;
        font-size: vw(22px);
        line-height: 100%;
        margin: 0 0 20px;

        @media only screen and (max-width: $xs-max) {
            font-size: vw(16px, true);
        }
    }
}

.surtitle {
    margin: 0 0 20px;
    text-transform: uppercase;
}

//
// Text styles
// -----------------------------------------------------------------------------

p {
    @include otterco-light();
    color: map-get($colors-base, text);
    letter-spacing: 0.05rem;
    font-size: rem(20);
    line-height: 150%;

    &.x-large {
        font-size: rem(48);
        line-height: 100%;

        @media only screen and (max-width: $sm-max) {
            font-size: rem(38);
        }
    }
    &.large {
        font-size: rem(28);
        line-height: 130%;

        @media only screen and (max-width: $sm-max) {
            font-size: rem(22);
        }
    }
    &.medium {
        font-size: rem(20);
        line-height: 150%;
    }
    &.small {
        font-size: rem(16);
        line-height: 140%;
    }
    &.x-small {
        @include otterco-regular();
        font-size: rem(14);
        line-height: 140%;
    }
}

strong {
    @include otterco-bold();
}

em {
    font-style: italic;
}

.no-margin {
    margin: 0;
}

//
// Link styles
// -----------------------------------------------------------------------------

a {
    @include otterco-regular();
    color: map-get($colors-base, text);
    text-decoration: underline;

    &:hover, &:focus, &:active, &:visited {}
}

//
// List styles
// -----------------------------------------------------------------------------

ul {
    list-style: none;
    padding: 0;

    li{
        position: relative;
        @include otterco-light();
        padding: 0 0 0 23px;
        margin: 0;

        @include otterco-light();
        color: map-get($colors-base, text);
        letter-spacing: 0.05rem;
        font-size: rem(20);
        line-height: 1.5;

        &:before{
            content: '';
            display: block;
            width: 3px;
            height: 3px;
            position: absolute;
            top: 11px;
            left: 10px;
            transform: translateY(-50%);
            background: map-get($colors-base, text);
            border-radius: 50%;
        }
    }
}

ol {
    list-style: none;
    counter-reset: custom-counter;
    margin:0;
    padding: 0;

    li {
        position: relative;
        padding: 4px 0 0 30px;
        margin-bottom: 10px;

        @include otterco-light();
        color: map-get($colors-base, text);
        letter-spacing: 0.05rem;
        font-size: rem(20);
        line-height: 1.5;

        &::before {
            @include adieu();
            font-size: rem(22);
            content: counter(custom-counter);
            counter-increment: custom-counter;
            position: absolute;
            left: 0;
            top: 0;
        }

        &:nth-child(n + 10){
            padding: 4px 0 0 45px;
        }

        &:nth-child(n + 100){
            padding: 4px 0 0 70px;
        }
    }
}

li {}

//
// Form styles
// -----------------------------------------------------------------------------

input {}

.recaptcha{
    font-size: rem(12);
    line-height: rem(16);
    margin-top: 25px;
    letter-spacing: 0;
    margin-left: 10px;
}

img{
    width: auto;
    height: auto;
}


