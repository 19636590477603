@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.wysiwyg-single {
    contain: paint;
    overflow: unset;
    padding-block: 50px;

    .side-menu-wrapper {
        @include col-start(1);
        @include col(4);
        position: sticky;
        top: 120px;
    }

    .text-content {
        @include col-start(5);
        @include col(8);

        .header-image {
            max-height: 450px;
            margin-bottom: 60px;

            img {
                width: 100%;
                aspect-ratio: 891/456;
                max-height: 450px;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        p:first-child {
            margin-top: 0;
        }
        .raw-html-embed{
            > div{
                height: unset !important;
                iframe{
                    height: auto;
                    aspect-ratio: 16/9
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        &.with-mountain{
            padding-top: 0;
        }

        .side-menu-wrapper {
            padding-block: 0 60px;
            top: unset;
            position: relative;
            @include col(12);
        }
        .text-content {
            @include col-start(1);
            @include col(12);
        }
    }
}

