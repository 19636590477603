@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-tiles, .block-instagram, .home-activities{
    overflow: hidden;

    h3 {
        margin: 0 0 40px;
    }

    .centered-content {
        .swiper {
            position: relative;
            width: 100%;

            .swiper-arrows{
                display: flex;
                flex-direction: row;
                margin: 40px 0 0;
                gap: 10px;
                justify-content: flex-end;
            }

            .swiper-button-disabled{
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        h3 {
            margin: 0 0 20px;
        }

        .centered-content {
            .swiper {
                .swiper-arrows{
                    margin: 20px 0 0;
                }
            }
        }
    }
}
