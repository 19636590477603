@use "config" as *;
@use "mixins" as *;

.left {
    float: left;
}

.right {
    float: right;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.caps {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.text-hide {
    text-indent: -9999px;
}

.hide {
    display: none!important;
}

.hidden {
    visibility: hidden;
}

// Hide from both screenreaders and browsers: h5bp.com/u
.hide-all {
    display: none;
    visibility: hidden;
}

.clearfix {
    @include clearfix();
}
