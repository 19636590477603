@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

header.section {
    position: absolute;
    width: 100%;

    z-index: 998;
    padding: 0;
    margin: 0;
    overflow: unset;
    transition: background-color $defaultTransitionSpeed ease-in-out;

    background: transparent;

    &.light {
        .primary-navigation {
            border-top-color: map-get($colors-base, text-invert);

            .centered-content {
                .primary-navigation-wrapper {

                    li {
                        .logo{
                            path, rect, circle {
                                fill: map-get($colors-base, text-invert);
                            }
                        }

                        .special-nav-link {
                            color: map-get($colors-header, special-link-color-secondary);

                            &:hover {
                                color: map-get($colors-header, special-link-color-secondary-hover);
                            }
                        }

                        .icon-mobile-menu {
                            svg {
                                line {
                                    stroke: map-get($colors-base, text-invert);
                                }
                            }
                        }
                    }

                    > li {
                        >a:not(.primary-cta):not(.logo), >span {
                            &:before {
                                background-color: map-get($colors-base, text-invert);
                            }
                        }
                    }
                }


            }
        }

        .secondary-navigation{
            .centered-content{
                ul {
                    li {
                        .secondary-cta {
                            &.active{
                                color: map-get($colors-base, text);
                                background-color: map-get($colors-base, text-invert);
                            }
                        }
                    }
                }
            }
        }

        .secondary-navigation, .primary-navigation {
            .centered-content {
                ul {
                    li {
                        a, span {
                            color: map-get($colors-base, text-invert);

                            &:before {
                                background: map-get($colors-base, text-invert);
                            }
                        }

                        .icon-logo {
                            svg {
                                path {
                                    fill: map-get($colors-base, text-invert);
                                }
                            }
                        }

                        .secondary-cta {
                            border-color: map-get($colors-base, text-invert);
                            background: transparent;

                            &:hover {
                                color: map-get($colors-base, text);
                                background-color: map-get($colors-base, text-invert);
                            }
                        }
                    }
                }
            }
        }
    }

    &.opened-subnavigation, &.opened-mobile-menu {
        transition: background-color $defaultTransitionSpeed $ease-in-out-quad;

        .overlay-background {
            position: fixed;
            top: 0;
            height: 100vh;
            left: 0;
            right: 0;
            background-color: #0C120C;
            z-index: -2;

            opacity: 0.9;
            transition: opacity $defaultTransitionSpeed $ease-in-out-quad 100ms;
            pointer-events: none;

            @media only screen and (max-width: $sm-max) {
                display: none;
            }
        }

        &.opened-mobile-menu {
            background-color: map-get($colors-header, bg-opened-subnavigation);

            .secondary-navigation {
                background-color: map-get($colors-header, bg-opened-subnavigation);
            }

            .primary-navigation {
                background-color: map-get($colors-header, bg-opened-subnavigation);


            }
        }
    }
}
