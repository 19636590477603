@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.hour-form {
    &.disabled {
        pointer-events: none;
        .hour-btn {
            background-color: map-get($colors-base, bg-dark-beige);
            .text{
                p{
                    color: map-get($colors-base, text);
                }
            }
            .icon-arrow-right {
                opacity: 0;
            }
        }
    }

    .hour-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 8px 20px;
        border-radius: 30px;
        background-color: map-get($colors-buttons, primary-bg);
        transition: background-color $defaultTransitionSpeed $ease-in-out-quad;


        &:hover {
            background-color: map-get($colors-buttons, primary-hover-bg);
        }

        .text{
            display: flex;
            flex-direction: column;
            align-items: start;

            p{
                color: map-get($colors-base, text-invert);
                white-space: nowrap;

                &:first-child{
                    @include otterco-regular();
                }

                &:last-child {
                    font-size: rem(12);
                    line-height: rem(16.8);
                }
            }
        }

        .icon-arrow-right {
            display: flex;
            justify-content: end;
            margin: 0 0 0 30px;

            svg{
                width: auto;

                path {
                    stroke: map-get($colors-base, text-invert);
                }
            }
        }

    }

    &:last-child {
        margin: 0;
    }

    @media only screen and (max-width: $sm-max) {

    }

    @media only screen and (max-width: $xs-max) {
        width: calc(50% - 4px);

        .hour-btn {
            width: 100%;
        }
    }
}
