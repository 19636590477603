@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.jobs-form{
    h2 {
        margin: 0 0 80px;
    }
    form{
        @include col(8);
        @include col-start(5);

        .select-wrapper{
            width: 100%;
        }
    }

    @media only screen and (max-width: $xs-max){
        form{
            @include col-start(1);
            display: flex;
            flex-direction: column;
            button[type=submit]{
                align-self: flex-end;
            }
        }
    }
}
