@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.homepage-help-find-activities {
    overflow: clip;
    position: relative;

    .foreground-wrapper {
        position: absolute;
        right: 5%;
        bottom: -20%;
        width: 45%;
        max-height: 600px;
        aspect-ratio: 649/574;
        z-index:2;

        .foreground{
            position: absolute;
            z-index:2;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity:0;
            border-radius: 5px;
            transition: opacity 0.6s ease;

            &.active{
                opacity:1;
                z-index:3;
            }
        }

    }

    .background-wrapper{
        position: absolute;
        z-index:1;
        width: 34%;
        max-height: 450px;
        right: -5%;
        top: 60%;
        aspect-ratio: 490/505;

        .background{
            z-index:1;
            width: 100%;
            height: 100%;
            transform: translateY(-50%);
            aspect-ratio: 490/505;
            object-fit: cover;
            opacity:0;
            border-radius: 5px;
            transition: opacity 0.6s ease;

            &.active{
                opacity:1;
                z-index:2;
            }
        }
    }

    span.icon-topo{
        z-index:0;
        top: 45%!important;
    }

    form{
        position: relative;
        z-index:4;
        @include col-start(2);
        @include col(9);
        padding-bottom: 150px;

        p{
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 40px;
            color: map-get($colors-forms, suptitle)

        }

        h3{
            position: relative;
            z-index: 10;
            line-height: 1.6;
        }

        .select-wrapper{
            vertical-align: middle;
        }

        button{
            margin-top: 40px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        form{
            @include col-start(1);
            @include col(12);
        }
    }

    @media only screen and (max-width: $xs-max) {
        overflow: unset; // Bypasses issue with iOS devices and dropdown select with overflow clip
        //contain: paint;

       .foreground, .background{
           display: none;
       }

        form{
            padding-bottom:0;

            h3{
                line-height: 1.1;
            }

            .select-wrapper{
                display: block;
                &:first-of-type{
                    margin-bottom: 15px;
                }
            }

            br{
                display: none;
            }
        }
    }
}
