//
// COLORS
// Use http://www.color-blindness.com/color-name-hue/ for color naming
// -----------------------------------------------------------------------------

$nordic-dark-green: #132C2A;
$dark-blue-stone: #0F5753;
$light-blue-stone: #10635E;
$silver-tree-green: #57B787;
$narvik-beige: #E8E6DD;
$light-beige: #F7F6F0;
$white: #FFFFFF;
$timberwolf: #D6D6CC;
$light-cornflower: #ADDEEC;
$dark-cornflower: #98D6E7;
$darker-cornflower: #64ACC0;
$trinidad-orange: #C35033;
$trinidad-orange-hover: #B0442A;
$porsche-orange: #DD8752;
$dolly-yellow: #F2F27B;
$dolly-yellow-hover: #DFDF7B;
$salem-green: #14844C;



//
// MAP-GET() ARRAYS
// -----------------------------------------------------------------------------

$colors-base: (
    text: $nordic-dark-green,
    light-text: $dark-blue-stone,
    text-invert: $white,
    border: $dark-blue-stone,
    bg: $white,
    bg-dark-green: $dark-blue-stone,
    bg-light-green: $light-blue-stone,
    bg-beige: $narvik-beige,
    bg-light-beige: $light-beige,
    bg-dark-beige: $timberwolf,
    bg-blue: $dark-cornflower,
    bg-light-blue: $light-cornflower,
    bg-yellow: $dolly-yellow,
    bg-white: $white,
    error: $trinidad-orange-hover, // TEMP
    error-light: $trinidad-orange, // TEMP
    interactive: $salem-green
);

$colors-buttons: (
    link: $nordic-dark-green,
    link-hover: $dark-blue-stone,

    primary-bg: $dark-blue-stone,
    primary-border: $dark-blue-stone,
    primary-hover-bg: $nordic-dark-green,
    primary-active-bg: $nordic-dark-green,
    primary-color: $white,

    primary-inverse-bg: $white,
    primary-inverse-hover-bg: $narvik-beige,
    primary-inverse-active-bg: $narvik-beige,
    primary-inverse-color: $nordic-dark-green,

    primary-alt-bg: $dolly-yellow,
    primary-alt-hover-bg: $dolly-yellow-hover,
    primary-alt-active-bg: $dolly-yellow-hover,
    primary-alt-color: $nordic-dark-green,

    primary-highlight-bg: $trinidad-orange,
    primary-highlight-hover-bg: $trinidad-orange-hover,
    primary-highlight-active-bg: $trinidad-orange-hover,
    primary-highlight-color: $white,

    secondary-bg: transparent,
    secondary-border: $dark-blue-stone,
    secondary-hover-bg: $dark-blue-stone,
    secondary-active-bg: $dark-blue-stone,
    secondary-color: $dark-blue-stone,
    secondary-hover-color: $white,

    secondary-inverse-bg: transparent,
    secondary-inverse-border: $narvik-beige,
    secondary-inverse-hover-bg: $narvik-beige,
    secondary-inverse-active-bg: $narvik-beige,
    secondary-inverse-color: $white,
    secondary-inverse-hover-color: $nordic-dark-green,

    tertiary-color: $dark-blue-stone,
    tertiary-hover-color: $nordic-dark-green,
    tertiary-active-color: $nordic-dark-green,

    tertiary-alternate-color: $white,
    tertiary-alternate-hover-color: $narvik-beige,
    tertiary-alternate-active-color: $narvik-beige,
);

$colors-tile-hover: (
    fill-blue: $dark-cornflower,
    fill-orange: $trinidad-orange,
    fill-yellow: $dolly-yellow,
    fill-green: $silver-tree-green,
    fill-dark-green: $dark-blue-stone,
);

$colors-forms: (
    background: $dark-blue-stone,
    background-active: $nordic-dark-green,
    text: $dark-blue-stone,
    background-light: $white,
    background-light-beige: $light-beige,
    //background-hover: $nordic-dark-green,
    background-hover: $light-beige,
    border: $dark-blue-stone,
    border-active: $nordic-dark-green,
    error: $trinidad-orange,
    success: $silver-tree-green,
    label: $white,
    label-dark: $dark-blue-stone,
    label-darker: $nordic-dark-green,
    label-hover: $white,
    label-hover-dark: $dark-blue-stone,
    label-active: $nordic-dark-green,
    label-active-light: $white,
    label-upload: $dolly-yellow,
    label-upload-hover: $dolly-yellow-hover,
    disabled: $darker-cornflower,
    suptitle: $dark-cornflower
);

$colors-select-big: (
    svg-light: $dolly-yellow,
    svg-white: $white,
    selected-light: $dolly-yellow,
    border-light: $dolly-yellow,
    border-white: $white,
    svg-dark: $dark-blue-stone,
    selected-dark: $dark-blue-stone,
    border-dark: $dark-blue-stone,
    option: $white,
    selected-opened: $dolly-yellow,
    option-hover: $dolly-yellow,
    bg-opened: $nordic-dark-green,
);

$colors-header: (
    border: $nordic-dark-green,
    bg-fixed: $light-beige,
    bg-opened-subnavigation: $light-beige,
    subnavigation-text-hover: $dark-blue-stone,
    special-link-color: $trinidad-orange,
    special-link-color-hover: $trinidad-orange-hover,
    special-link-color-secondary: $dolly-yellow,
    special-link-color-secondary-hover: $dolly-yellow-hover
);

$colors-footer: (
    bg: $narvik-beige,
);

$colors-component: (
    light: $white,
    dark: $nordic-dark-green,
    bg-block-cta: $narvik-beige,
);

$colors-pagination: (
    light: $white,
    active: $porsche-orange
);

$colors-tiles: (
    special-text: $dolly-yellow,
    new-tag: $dolly-yellow,
);

$colors-rates: (
    text: $white,
    text-secondary: $dark-cornflower,
    text-dark: $nordic-dark-green,
    text-green: $dark-blue-stone,
    primary-bg: $dark-blue-stone,
    secondary-bg: $light-blue-stone,
    tertiary-bg: $dark-cornflower,
    border: $light-blue-stone,
    link-color-secondary: $dolly-yellow,
    link-color-secondary-hover: $dolly-yellow-hover
);

$colors-overlay: (
    background: $dark-cornflower,
    background-light: $narvik-beige,
)
