@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.payment-cart {
    .form-wrapper {
        @include col(6);
        @include col-start(2);
        margin-left: calc(-1 * var(--gutter-width));
        margin-right: var(--gutter-width);

        .btn-return {
            display: flex;
        }

        form{
            .accordion {
                padding: 30px 0;
                border-color: map-get($colors-base, bg-dark-beige);

                .accordion-content-wrapper {
                    display: block;

                    .accordion-title {
                        h5{
                            display: flex;
                            align-items: center;
                            gap: 20px;
                            text-transform: none;
                            margin: 0;

                            span {
                                width: 37px;
                                height: 37px;
                                border-radius: 50%;
                                border: 1px solid map-get($colors-base, border);
                                background-color: map-get($colors-base, bg-dark-green);

                                display: flex;
                                flex: 0 0 auto;
                                justify-content: center;
                                align-items: center;

                                color: map-get($colors-base, text-invert);
                                @include otterco-semibold();
                                font-size: rem(18);
                                line-height: rem(18);
                            }
                        }
                    }
                    .accordion-trigger {
                        display: none;
                    }
                }
            }
        }

        @media only screen and (max-width: $md-max) {
            @include col(7);
            @include col-start(1);
            margin-left: 0;
            margin-right: var(--gutter-width);
        }

        @media only screen and (max-width: 1220px) {
            @include col(12);
            @include col-start(1);
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 50px;

            order: 2;

            .btn-return {
                display: none;
            }
        }
    }
}
