@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

section.footer-newsletter{
    padding: 100px 0;
    background-color: map-get($colors-footer, bg);

    .newsletter-icon {
        @include col(4);
        @include col-start(2);
    }

    .newsletter-form {
        @include col(5);
        @include col-start(6);
        justify-self: flex-start;

        p.title {
            font-size: rem(28);
            line-height: rem(36.4);
            margin: 0 0 30px;
            text-transform: uppercase;
        }

        .success-message, .error-message {
            margin-block: 0;
            margin-left: 10px;
        }
        .submit{
            span{
                display: none;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        padding: 60px 0 70px;

        .newsletter-icon {
            @include col(12);
            @include col-start(1);
            margin: 0 0 30px;
        }

        .newsletter-form {
            @include col(12);
            @include col-start(1);
            justify-self: unset;

            p {
                &.title{
                    font-size: rem(38);
                    line-height: rem(38);
                }
                &.recaptcha{
                    font-size: rem(12);
                    line-height: rem(16);
                }
            }
        }
    }
    @media only screen and (max-width: $xs-max) {
        .newsletter-form {
            .submit{
                max-width: 62px;
                font-size: 0; // remove text
                padding: 14px 17px;

                span{
                    display: block;
                    margin-left: 2px;

                    position: relative !important;
                    right: unset !important;
                    top: unset !important;
                    transform: unset !important;
                }
            }
        }
    }

}

