@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;


.side-menu-wrapper {
    .tags-wrapper {
        .tag {
            background: map-get($colors-base, bg-light-beige);
        }
    }

    .suptitle {
        text-transform: uppercase;
        margin-block: 0 15px;
    }


    .disponibility-wrapper {
        margin: 40px 0 0;
        .description {
            margin-block: 0 40px;
        }
    }

    .description {
        margin-block: 40px;

        p {
            margin-block: 0;
        }
    }

    a {
        &.primary-cta {
            padding-block: 17px 16px;

            .icon-external-link {
                width: 13.5px;
                margin-top: -1px;
                margin-left: 10px;
            }
        }
    }

    button:first-child:last-child {
        display: none; /* Hide button if it's the only one */
    }

    @media only screen and (max-width: $xs-max) {
        a {
            &.primary-cta {
                .icon-external-link {
                    width: 9.5px;
                    margin-top: 0;
                    margin-left: 9px;
                }
            }
        }
    }
}
