@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.mobile-navigation {
    position: fixed;
    top: -100vh;
    z-index: -1;

    left: 0;
    transition: top 1s cubic-bezier(.19,1,.22,1);

    pointer-events: none;

    width: 100vw;
    height: calc(100vh - 79px);

    overflow: hidden;

    &.open {
        top: 79px;
        pointer-events: auto;
    }

    > span[class^=icon-] {
        &.icon-topo {
            position: absolute;
            height: 500px;
            aspect-ratio: 2;
            left: -50vw;
            top: 23vh;
            transform: unset;
            pointer-events: none;
            z-index:0;
        }
    }

    .lang-switcher-mobile {
        position: absolute;
        left: 0;
        top: calc(100dvh - 145px);
        width: 100vw;
        z-index: 1;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            column-gap: 6px;

            li {
                padding-inline: 0;

                &:first-child {
                    padding-left: var(--gutter-width);
                }

                &:last-child {
                    padding-right: var(--gutter-width);
                }

                &:before {
                    display: none;
                }

                .secondary-cta {
                    padding: 5px 13px 6px;
                    text-transform: unset;
                }
            }
        }

        .language-link {
            a {
                text-decoration: none;
            }

            margin: 0 0 0 auto;
        }
    }

    .mobile-subnavigation {
        position: absolute;
        padding: 56px var(--gutter-width);
        background-color: map-get($colors-header, bg-opened-subnavigation);

        width: 100vw;
        height: calc(100vh - 79px);

        &.secondary-level-navigation {
            opacity: 0;
            pointer-events: none;
            transition: opacity $defaultTransitionSpeed ease-in-out;

            &.active {
                opacity: 1;
                pointer-events: auto;
            }

            ul {
                li {
                    a, button {
                        font-size: rem(36);
                        line-height: 1;
                    }
                }
            }
            &.parks-mobile-navigation{
                ul{
                    li{
                        margin-bottom: 20px;
                        a{
                            font-size: rem(32);
                        }
                    }
                }
            }
        }

        ul {
            padding: 0;

            &.has-view-all-link {
                li {
                    &:last-child {
                        margin-top: 55px;

                        a {
                            display: inline-block;
                            position: relative;
                            @include otterco-regular();
                            font-size: rem(14);
                            line-height: rem(18.27);
                            padding-bottom: 3px;

                            &:after {
                                display: block;
                                content: ' ';
                                position: absolute;

                                top: 100%;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                background-color: map-get($colors-buttons, tertiary-color);

                                transition: background-color $defaultTransitionSpeed ease-in-out;
                            }
                        }
                    }
                }
            }

            li {
                padding: 0;
                margin: 0 0 30px;

                &:last-child {
                    margin: 0;
                }

                &:before {
                    display: none;
                }

                &.btn-return {
                    margin: 0 0 40px;

                    button {
                        font-size: rem(16);
                        line-height: rem(24);

                        .icon-arrow-down {
                            flex: 0 0 auto;
                            width: 19px;
                            transform: rotate(-90deg);
                            margin: 0 10px 0 0;
                            pointer-events: none;

                            svg {
                                path {
                                    stroke: map-get($colors-header, subnavigation-text);
                                }
                            }
                        }
                    }
                }

                a, button {
                    padding: 0;

                    display: flex;
                    align-items: center;

                    @include otterco-light();
                    font-size: rem(50);
                    line-height: rem(50);
                    color: map-get($colors-base, text);
                    text-decoration: none;
                    text-transform: uppercase;
                    text-align: left;

                    &.special-nav-link {
                        color: map-get($colors-header, special-link-color);
                    }

                    .icon-arrow-down {
                        flex: 0 0 auto;
                        width: 24px;
                        transform: rotate(90deg);
                        margin: 0 0 0 20px;
                        pointer-events: none;

                        svg {
                            path {
                                stroke: map-get($colors-header, subnavigation-text);
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-height: 700px) and (max-width: $xs-max) {
        .mobile-subnavigation {
            padding-block: 0;

            ul {
                &.has-view-all-link {
                    li {
                        &:last-child {
                            margin-top: 25px;
                        }
                    }
                }

                li {
                    margin-bottom: 22px;

                    &.btn-return {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
