@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.scrolling-text {
    display: block;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    padding: 75px 0 180px;
    background: map-get($colors-base, bg-light-beige);

    .scrolling-text__track {
        position: relative;
        z-index: 1;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        animation: marquee 17s linear infinite;

        p{
            pointer-events: none;
            display: inline-block;

            @include adieu();
            color: map-get($colors-base, text);

            font-size: rem(300);
            line-height: rem(360);

            margin: 0 40px 0 0;

            &:last-child{
                margin: 0;
            }

        }
    }

    .primary-cta {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        &:hover ~ .image-wrapper {
            height: 484px;

        }
    }

    .image-wrapper{
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        height: 0;
        transition: height 1s cubic-bezier(.19,1,.22,1);
        border-radius: 5px;
        overflow: hidden;
        width: 391px;

        .image-hover {
            position: relative;
            width: 100%;
        }
    }

    @media only screen and (max-width: $sm-max) {
        padding: 0 0 65px;

        .scrolling-text__track {
            p{
                font-size: rem(200);
                line-height: rem(240);

                margin: 0 20px 0 0;
            }
        }

        .image-hover {
            display: none;
        }
    }
}

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-50%, 0, 0);
    }
}
