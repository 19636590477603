@import "../../../config";
@import "../../../mixins";
@import "../../../functions";

.image-overlay{

    .backdrop {
        background-color: rgba(0,0,0,0.3);
    }

    .wrapper{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        max-width: 840px;
        width: calc(100vw - 80px);
        padding: 0;
        margin: 0 auto;

        @media only screen and (max-width: $xs-max) {
            max-width: calc(100vw - 40px);
            width: auto;
            height: auto;
            max-height: calc(100vh - 40px);
        }

        img {
            width: 100%;
            height: 100%;
        }

        .close{
            position: absolute;
            z-index: 2;
            top: 15px;
            right: 15px;
            transition: opacity ease 250ms;
            &:hover{
                opacity: 0.9;
                transition: opacity ease 250ms;
            }
            span{
                width: 17px;
            }
        }

        .popup-image {
            display: block;

            @media only screen and (max-width: $xs-max) {
                display: none;
            }
        }

        .popup-image-mobile {
            display: none;

            @media only screen and (max-width: $xs-max) {
                display: block;
            }
        }

        .popup-link {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

    }
}
