@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.activities-filters{
    padding:0;
    overflow: visible;

    form{
        .subtext{
            @include col(6);

            .like-h3{
                display: inline-block;
                margin-bottom: 35px;
            }
        }

        .select-wrapper{
            display: inline-block;
            margin-bottom: 0;
            vertical-align: middle;
        }

        .secondary-filters{
            padding-bottom: 40px;
            border-bottom:1px solid white;
            margin-bottom: 20px;

            .secondary-cta{
                margin-right: 10px;
                margin-bottom: 10px;
                text-transform: unset;
            }
        }
    }

    .item-count{
        margin-bottom:0;
    }

    @media only screen and (max-width: $xs-max){
        form {
            .text-wrapper {
                .like-h3 {
                    br {
                        display: none;
                    }
                }
            }

            .secondary-filters{
                .secondary-cta{
                    margin-right: 5px;
                    &.small{
                        font-size: rem(13.75);
                        padding:11px 13px 10px;
                    }
                }
            }
            .select-wrapper {
                top: 3px;

                .choices {
                    .choices__inner {
                        padding: 0 100px 10px 0;
                    }
                }
            }
        }
    }
}
