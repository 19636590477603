@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.home-activities {
    position: relative;

    &.with-mountain {
        padding-top: 50px;
    }

    .centered-content {
        .swiper {
            position: relative;
            overflow: unset;

            .swiper-arrows{
                display: none !important;

                @media only screen and (max-width: $xs-max) {
                    display: flex !important;
                }
            }
        }
    }

    > span[class^=icon-]{
        &.icon-topo{
            height: 65%;
            aspect-ratio: 1.5;
            top: 30px;
            left: 20%;
            transform: translate(0%, 0%)
        }
    }

    .title {
        margin-bottom: 60px;
        font-size: rem(20);
        line-height: rem(30);

        h2 {
            margin: 0;
        }

        .subtitle {
            display: flex;
            align-items: center;
            margin-block: 0 20px;
            text-transform: uppercase;
            color: map-get($colors-base, light-text)
        }

        .row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 40px;

            a {
                margin-top: 5px;
                flex: 0 0 auto;
            }
        }

    }

    .tile {
        &.activity-tile{
            .inner-wrapper{
                .text-wrapper{
                    .infos{
                        span[class^=icon-]{
                            width: 30px;

                            &.icon-smile {
                                width: 31px;
                            }

                            path{
                                stroke: map-get($colors-base, interactive);
                            }
                        }
                    }

                }
            }
        }
        .image-wrapper {
            aspect-ratio: 350/360;
            border-radius: 5px;
            margin-bottom: 10px;

            img {
                aspect-ratio: 350/360;
                width: 100%;
            }
        }

        &:hover {
            .image-wrapper {
                .icon-tile-mask {
                    width: 105%;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .title {
            margin-bottom: 40px;

            .subtitle {
                font-size: rem(20);
                line-height: rem(24);
                white-space: nowrap;
                letter-spacing: 0.5px;
                column-gap: 10px;

            }
            .row{
                align-items: flex-start;
                flex-direction: column;
                gap: 25px;

                a{
                    margin: 0;
                }
            }
        }
        span[class^=icon-].icon-spacer{
            margin-top: 50px;
        }
    }
}

