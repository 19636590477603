@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.faq {
    padding: 0;

    .title-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 0 0 80px;

        h3 {
            margin: 0;
        }
    }

    //.menu-accordion-wrapper {
    //    .side-menu-wrapper {
    //        &.no-sticky{
    //            position: relative !important;
    //        }
    //    }
    //}

    @media only screen and (max-width: $xs-max) {
        &.menu-accordion{
            padding-top:0;
        }
        .title-wrapper{
            margin: 0 0 30px;

            .secondary-cta{
                display: none;
            }
        }
        .menu-accordion-wrapper{
            .select-wrapper{
                &.primary{
                    display: block;
                }
            }
        }
    }
}
