
@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-text-image {
    position: relative;
    --column-list: var(--column-amount);
    overflow: hidden;


    &.no-animation{
        .centered-content {
            .image-content {
                overflow: hidden;

                img {
                    scale: 1;
                }
            }
        }
    }

    &.align-left {
        &.bigger-image{
            .centered-content {
                .text-wrapper {
                    @include col-start(7);
                    @include col(5);
                }
                .image-content {
                    @include col-start(1);
                    right: calc(var(--gutter-width)*2);
                    left: auto;
                }
            }
        }

        .centered-content {
            .text-wrapper {
                order: 2;
                @include col-start(7);
                @include col(5);
            }

            .image-content {
                order: 1;
                @include col-start(2);
                @include col(4);
            }
        }
    }

    &.bigger-image{
        .centered-content {
            .text-wrapper {
                align-self: flex-end;
                margin-bottom: 80px;
                @include col-start(2);
                @include col(4);
            }
            .image-content {
                aspect-ratio: 699/800;
                @include col-start(7);
                @include col(6);
                left: calc(var(--gutter-width)*2);
                max-height: 100vh; // for big screens
                img{
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
        }
    }

    &:not(.bigger-image){
        .centered-content{
            .image-content{
                aspect-ratio: 426/528;
                max-height: 100vh; // for big screens
                img{
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
            .text-wrapper{
                align-self: flex-end;
                margin-bottom: 40px;
            }
        }
    }

    &.ajusted-style{
        .centered-content {
            .text-wrapper {
                .text-content{
                    .wysiwyg {
                        h5{
                            margin: 50px 0 20px;
                        }
                        p{
                            font-size: rem(16);
                            line-height: 140%;
                        }
                    }
                }
            }
        }
    }

    .centered-content {
        .text-wrapper {
            @include col-start(2);
            @include col(5);

            .text-content{
                .surtitle{
                    color: map-get($colors-base, light-text);
                }
                h2{
                    margin: 0 0 30px;
                }
                .wysiwyg {
                    font-size: rem(20);
                    line-height: 150%;

                    ul{
                        li{
                            list-style: none;
                        }
                    }
                }
            }

            .secondary-cta {
                margin: 40px 0 0;
            }
        }



        .image-content {
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            aspect-ratio: 426/528;
            @include col-start(8);
            @include col(4);

            img {
                height: 100%;
                min-height: 100%;
                max-width: none;
                scale:1.4;
            }
        }
    }

    @media only screen and (min-width: 2000px){
        &.bigger-image{
            .centered-content {
                .image-content {
                    width: 100%;
                    max-height: 90vh; // for big screens
                    img{
                        height: 100%;
                    }
                }
            }
        }

        &:not(.bigger-image){
            .centered-content{
                .image-content{
                    width: 100%;
                    max-height: 75vh; // for big screens
                    img{
                        height: 100%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        + .pagebuilder-component-text-image {
            padding-top: 0;
        }

        &.align-left {
            &.bigger-image{
                .centered-content {
                    .text-wrapper {
                        @include col-start(1);
                        @include col(12);
                    }
                    .image-content {
                        @include col-start(1);
                        right: auto;
                        left: auto;
                    }
                }
            }

            .centered-content {
                gap: 30px;

                .text-wrapper {
                    order: 2;
                    @include col-start(1);
                    @include col(12);
                }

                .image-content {
                    aspect-ratio: 336/377;
                    order: 1;
                    @include col-start(1);
                    @include col(12);
                }
            }
        }

        &.bigger-image{
            .centered-content {
                .text-wrapper {
                    margin-top: 10px;
                    @include col-start(1);
                    @include col(12);
                    align-self: unset;
                    margin-bottom: unset;
            }
            .image-content {
                aspect-ratio: 336/377;
                @include col-start(1);
                @include col(12);
                left: auto;
                img{
                    height: 100%;
                }
            }
        }
    }
    &:not(.bigger-image){
        .centered-content{
            .image-content{
                aspect-ratio: 336/377;
                img{
                    height: 100%;
                }
            }
            .text-wrapper{

                align-self: unset;
                margin-bottom: unset;
            }
        }
    }

    .centered-content {
        gap: 30px;
        align-items: unset;
        grid-template-rows: 1fr;

        .text-wrapper {
            margin-top: 20px;
            order: 2;
            @include col-start(1);
            @include col(12);

            .text-content{
                margin: 0;
            }

            .secondary-cta {
                margin: 30px 0 0;
            }
        }
        .image-content {
            display: flex;
            order: 1;
            margin: 0;
            @include col-start(1);
            @include col(12);

            img{
                height: unset;
                min-width: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

}

}
