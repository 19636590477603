@use "config" as *;
@use "mixins" as *;

@media print {
	* {
		background: transparent !important;
		color: black !important;
		text-shadow: none !important;
		filter:none !important;
		-ms-filter: none !important;
		border: none !important;
		overflow: visible !important;
		position: static !important;
	}
}
