@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

.big-tile {
    position: relative;
    z-index: 1;

    @include grid(var(--column-amount), var(--gutter-width));
    margin: 0 0 80px;

    &:last-child {
        margin: 0;
    }

    &:hover{
        .image-wrapper{
            .icon-tile-mask{
                width: 100%;
            }
            img{
                transform: scale(1.05) translate(-47.5%, -47.5%);
            }
        }

        .text-wrapper{
            .text-bottom{
                .actions{
                    height: 75px;
                }
            }
        }
    }

    .cta-tile{
        inset: 0;
        z-index: 5;
        position: absolute;
        cursor: pointer;
        &:hover{
            .image-wrapper{
                .icon-tile-mask{
                    width: 100%;
                }
            }

            .text-wrapper{
                .text-bottom{
                    .actions{
                        height: 75px;
                    }
                }
            }
        }
    }

    .image-wrapper{
        @include col(5);
        @include col-start(1);

        aspect-ratio: 571/373;
        position: relative;
        overflow: hidden;
        z-index: 1;

        border-radius: 5px;

        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            object-fit: cover;
            width: 100%;
            transition: transform $slowerTransitionSpeed ease-in-out;
        }
    }

    .text-wrapper{
        display: flex;
        flex-direction: column;

        @include col(7);
        @include col-start(6);

        height: 100%;

        .text-top{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .distance{
                text-transform: uppercase;
                margin: 0;
                color: map-get($colors-base, light-text);
            }
        }

        .text-middle {
            @include col(6);
            @include col-start(1);

            flex: 1 1 auto;

            .title {
                text-transform: uppercase;
                margin: 0 0 20px;
            }

            .region{
                display: flex;
                align-items: start;
                text-transform: uppercase;
                margin: 0 0 20px;

                .icon-map-pin {
                    margin: 3px 7px 0 0;
                }
            }

            .description{
                max-width: calc(85% - 5px);
                * {
                    font-size: rem(16);
                    line-height: rem(22.4);
                }
            }
        }

        .text-bottom {
            .actions {
                position: relative;
                bottom: 0;
                z-index: 10;

                height: 0;
                overflow: hidden;
                display: flex;
                align-items: end;
                transition: height $defaultTransitionSpeed ease-in-out;
                column-gap: 10px;

            }
        }
    }
    @media only screen and (max-width: $sm-max) {
        .text-wrapper {
            @include grid(var(--column-amount), var(--gutter-width));
            .text-middle {
                @include col(12);
                .description{
                    word-break: break-word;
                    max-width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        margin: 0 0 40px;

        &:last-child {
            margin: 0;
        }

        &:hover{
            .image-wrapper{
                img{
                    transform: translate(-50%, -50%);
                }
                .icon-tile-mask{
                    width: 200%;
                }
            }

            .text-wrapper{
                .text-bottom{
                    .actions{
                        height: auto;
                    }
                }
            }
        }

        .image-wrapper{
            @include col(12);
            @include col-start(1);

            margin: 0 0 20px;
        }

        .text-wrapper{
            @include col(12);
            @include col-start(1);

            height: auto;

            .text-top{
                h6{
                    font-size: 0.875rem;
                }
            }

            .text-middle{
                .description{
                    margin: 0 0 30px;
                }
            }

            .text-bottom {
                .actions {
                    width: 100%;
                    margin: 30px 0 0;
                    height: auto;
                    gap: 10px;
                    flex-wrap: wrap;
                    > a{
                        flex-grow: 1;
                    }
                }
            }
        }
    }
}
