@use "../../../config" as *;
@use "../../../mixins" as *;

.share{
    display:flex;
    position: relative;
    width: fit-content;
    margin-top: 40px;

    &:hover, &:active{
        .a2a_kit{
            opacity:1;
            pointer-events: auto;

            a{
                span{
                    background-color: transparent;
                    svg{
                        path{
                            fill: map-get($colors-base, bg-dark-green) !important;
                        }
                    }


                }
            }
        }
    }

    .a2a_svg{
        width: 28px;
        height: 28px;
        line-height: rem(28);
    }

    .a2a_kit{
        pointer-events: none;
        opacity:0;
        background: map-get($colors-base, bg);
        border: map-get($colors-base, border) 1px solid;
        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        width: fit-content;
        padding: 20px;
        height: 50px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 40px;
        transform: translateX(-50%);

        &:before{
            //spacer so we dont lose mouseover in the space between the button and the share container
            content: ' ';
            width: 100%;
            height: 10px;
            position: absolute;
            top: -10px;
            left:0;
        }

        &:after{
            //triangle
            content: ' ';
            width: 0;
            height: 0;
            position: absolute;
            top: -5px;
            left:50%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid map-get($colors-base, border);
        }
    }

    @media only screen and (max-width: $sm-max) {
        .a2a_kit{
            left: 100%;
        }
    }
}
