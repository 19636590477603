@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.booking-tile {
    position: relative;
    cursor: auto;

    @include grid(var(--column-amount), var(--gutter-width));
    padding: 0 0 30px;
    border-bottom: 1px solid map-get($colors-base, bg-dark-beige);
    margin: 0 0 30px;

    &:last-child {
        margin: 0;
        border-bottom: none;
    }

    .image-wrapper{
        @include col(5);
        @include col-start(1);

        aspect-ratio: 236/150;
        position: relative;
        overflow: hidden;

        border-radius: 5px;

        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            object-fit: cover;
            width: 100%;
            transition: transform $slowerTransitionSpeed ease-in-out;
        }
    }

    .text-wrapper{
        display: flex;
        flex-direction: column;

        @include col(7);
        @include col-start(6);

        height: 100%;

        .text-middle {
            @include col(6);
            @include col-start(1);
            margin: 0 0 20px;

            .large.title {
                margin: 10px 0;
                text-transform: uppercase;
            }

            .infos{
                display:flex;
                gap: 5px;
                row-gap: 15px;
                align-items: center;
                flex-wrap: wrap;

                >span{
                    display:flex;
                    align-items: center;
                    font-size: rem(14);
                    line-height: rem(19.6);

                    span[class^="icon-"] {
                        background-color: map-get($colors-base, bg-dark-beige);
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        padding: 4px;
                    }

                    svg{
                        width: 100%;
                        path {
                            stroke: map-get($colors-base, text);
                        }
                    }

                    &.age{
                        .icon-smile {
                            padding: 4px 3px 3px 4px;
                        }
                    }

                    &.price{
                        .icon-price {
                            flex: 0 0 auto;
                            padding: 5px 4px 4px 5px;
                            svg {
                                path {
                                    stroke: map-get($colors-base, text);
                                    fill: map-get($colors-base, text);
                                }
                            }
                        }
                    }

                    >span{
                        margin-right: 14px;
                    }
                }
            }
        }
    }

    .actions-wrapper {
        margin: 30px 0 0;

        .selected-date{
            text-transform: capitalize;
        }

        .btn-list {
            margin: 10px 0 0;

            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        .text-wrapper {
            @include grid(var(--column-amount), var(--gutter-width));
            .text-middle {
                @include col(12);
                .description{
                    word-break: break-word;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        margin: 0 0 40px;

        &:last-child {
            margin: 0;
        }

        .image-wrapper{
            @include col(12);
            @include col-start(1);

            margin: 0 0 20px;
        }

        .text-wrapper{
            @include col(12);
            @include col-start(1);

            height: auto;
        }
    }
}
