@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.homepage-parks {
    overflow: clip;

    &.dark-green {
        .icon-map-pin svg path {
            stroke: #fff;
        }
    }

    .heading {
        @include col(12);
        @include grid(12, var(--gutter-width));
        justify-content: space-between;
        margin-bottom: 50px;

        p {
            text-transform: uppercase;
        }

        h2 {
            @include col(8);
            position: relative;
            z-index: 10;
            .select-wrapper{
                .choices{
                    .choices__inner {
                        .choices__list {
                            .choices__item {
                                @include adieu();
                                font-weight: 400;
                                font-size: vw(70px);
                                line-height: 100%;
                            }
                        }
                    }
                }
            }
        }

        .title {
            @include col(7);
        }

        p.title {
            text-transform: uppercase;
            margin: 0;
            align-self: end;
            padding-bottom: 20px;
        }

        hr {
            margin: 0;
            width: 100%;
        }

        .button {
            @include col(4);
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 40px;
        }
    }

    .scrolling-section {
        position: relative;
        @include grid(12, var(--gutter-width));
        height: 300vh;
        min-height: 2100px;
        transition: opacity 0.3s ease;

        &.in-transition {
            opacity: 0;
        }
    }

    .parks-section {
        flex-direction: column;
        position: absolute;
        width: 50%;
        top: 0;
        left: 0;

        @include grid(6, var(--gutter-width));

        .park {
            @include col(4);
            @include col-start(2);
            position: relative;
            display: flex;
            align-items: center;
            height: 100vh;
            min-height: 700px;

            .icon-topo{
                pointer-events: none;
                z-index:0;
            }

            .infos {
                position: relative;
                transition: color $defaultTransitionSpeed linear;
                z-index: 1;

                picture {
                    display: none;
                }

                .subtitle-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 30px;
                    margin-bottom: 10px;

                    .distance{
                        text-transform: uppercase;
                        margin-top: 5px;
                        line-height: 1.2;
                    }

                    h6, p {
                        margin: 0;
                    }
                }

                h6 {
                    text-transform: capitalize;
                }

                .title {
                    @include otterco-light();
                    text-transform: uppercase;
                }

                .region {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    .icon-map-pin{
                        margin-right: 5px;

                        path {
                            transition: stroke $defaultTransitionSpeed linear;
                        }
                    }
                }

                .description {
                    margin-bottom: 30px;
                    word-break: break-word;

                    *:first-child {
                        margin-top: 0;
                    }

                    *:last-child {
                        margin-bottom: 0;
                    }
                }

            }
        }
    }

    .images-section {
        position: sticky;
        top: 0;
        left: 50%;
        margin-right: calc(-1 * var(--gutter-width));
        width: calc(50% + var(--gutter-width));
        height: 100vh;
        min-height: 700px;

        picture {
            position: absolute;

            img{
                height: 100vh;
                min-height: 700px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                object-fit: cover;
            }

            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    z-index: #{10 - $i};
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .parks-section {

            .park {
                padding: 0 30px 0 10px;
                @include col(6);
                @include col-start(1);
            }
        }
}

    @media only screen and (max-width: $xs-max) {
        .heading{
            h2, .title {
                @include col(12);
            }

            h2 {
                .select-wrapper{
                    .choices{
                        .choices__inner {
                            .choices__list {
                                .choices__item {
                                    font-size: vw(36px, true);
                                }
                            }
                        }
                    }
                }
            }

            .button {
                @include col(12);
                justify-content: flex-start;
                padding-bottom: 30px;
            }
        }

        .scrolling-section {
            height: auto;
        }

        .parks-section {
            width: 100%;
            position: relative;
            @include grid(12, var(--gutter-width));

            .park {
                @include col(12);
                @include col-start(1);
                padding:0;
                height: auto;
                margin-bottom: 50px;

                &:last-child{
                    margin-bottom: 20px;
                }

                .infos {
                    .description{
                        margin-bottom: 20px;
                    }
                    .subtitle-wrapper{
                        margin-top: 20px;

                        *{
                            line-height: 1.2rem;
                        }

                        .distance{
                            margin-top: 3px;
                        }
                    }

                    h3{
                        margin-bottom: 15px;
                    }

                    .count{
                        display: none;
                    }

                    picture {
                        display: block;

                        img{
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        .images-section{
            display: none;
        }
    }
}
