@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.tile {
    position: relative;

    &:not(.no-hover) {
        &:hover {
            .image-wrapper {
                .icon-tile-mask {
                    width: 102%;
                }

                img {
                    transform: scale(1.05) translate(-47.5%, -47.5%);
                }
            }
        }
    }

    &.giftcard-tile {
        .image-wrapper {
            aspect-ratio: 507/317;
        }
        .button-buy{
            position: relative;
            z-index: 6;
            margin-top: 15px;
        }
    }

    &.activity-tile,
    &.news-tile,
    &.promotion-tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.text-tile {
            padding: 40px 30px;
            background-color: map-get($colors-base, bg-beige);
            height: auto;
            transition: background-color $defaultTransitionSpeed $ease-in-out-quad;
            border-radius: 5px;
            overflow: hidden;

            &:hover {
                //background-color: map-get($colors-base, bg-beige);

                a {
                    color: map-get($colors-buttons, tertiary-hover-color);

                    &:after {
                        background-color: map-get($colors-buttons, tertiary-hover-color);
                    }
                }
            }

            .inner-wrapper {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;

                .text-wrapper {

                    .like-h3 {
                        margin: 0;
                    }
                }

                a {
                    align-self: start;
                }
            }

        }

        &.groups-tile {
            .icon-clock, .icon-thumbs-up {
                background-color: map-get($colors-base, bg-light-green);
                padding: 5.5px;
                border-radius: 100%;
                box-sizing: content-box;
            }

            .text-wrapper {
                .title {
                    margin-bottom: 10px;
                }
            }

            .duration, .benefits {
                width: 100%;

                span {
                    @include otterco-regular();
                }
            }

            .benefits {
                margin-bottom: 20px;
            }

            .tags {
                padding-block: 20px;
                border-top: 1px solid rgba(0, 0, 0, 0.15);
            }
        }

        &.small-tile {
            margin: 0 0 30px;

            .inner-wrapper {
                display: flex;
                gap: 30px;

                .image-wrapper {
                    flex: 0 0 auto;
                    height: 150px;
                    aspect-ratio: 237/150;

                    margin: 0;
                }

                .text-wrapper {
                    flex: 1 1 auto;

                    .title {
                        margin: 0 0 10px;
                    }

                    .text-content {
                        display: none;
                    }

                    .infos {
                        > span.price {
                            align-items: center;

                            .icon-price {
                                flex: 0 0 auto;
                            }
                        }

                        span[class^="icon-"] {
                            background-color: map-get($colors-base, bg-dark-beige);
                            height: 30px;
                            width: 30px;
                            border-radius: 50%;
                            padding: 4px;

                            &.icon-smile {
                                padding: 4px 3px 3px 4px;
                            }

                            &.icon-price {
                                padding: 5px 4px 4px 5px;

                                svg {
                                    path {
                                        stroke: map-get($colors-base, text);
                                        fill: map-get($colors-base, text);
                                    }
                                }
                            }

                            svg {
                                width: 100%;

                                path {
                                    stroke: map-get($colors-base, text);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.dark {
            .infos {
                > span {
                    &.price {
                        .icon-price {
                            svg {
                                * {
                                    stroke: none;
                                    fill: map-get($colors-base, text);

                                    &:last-child {
                                        stroke: map-get($colors-base, text) !important;
                                        fill: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            svg {
                * {
                    stroke: map-get($colors-base, text);
                }
            }
        }

        &:hover {
            .buttons {
                opacity: 1;
            }
        }

        a.cta-tile {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 5;
        }

        .text-wrapper {
            .text-content {
                margin-bottom: 20px;
            }
        }

        .infos {
            display: flex;
            gap: 20px;
            row-gap: 15px;
            align-items: center;
            flex-wrap: wrap;

            > span {
                display: flex;
                align-items: center;
                font-size: rem(14);
                line-height: rem(19.6);

                svg {
                    width: inherit;
                }

                &.price {
                    line-height: rem(20);

                    .icon-price {
                        svg {
                            path {
                                stroke: map-get($colors-base, text-invert);
                                fill: map-get($colors-base, text-invert);
                            }
                        }
                    }
                }

                > span {
                    margin-right: 14px;
                }
            }
        }

        .buttons {
            position: relative;
            z-index: 10;
            opacity: 0;
            display: flex;
            gap: 10px;
            margin-top: 24px;
            transition: opacity 0.3s ease;

            a, button {
                width: 50%;
            }
        }
    }

    &.news-tile,
    &.promotion-tile {
        .buttons {
            display: none;
        }
    }

    .overlay-link {
        z-index: 5;
        inset: 0;
        position: absolute;
    }

    &.show-prev-arrow {
        &.is-end {
            .image-wrapper {
                .prev-arrow {
                    left: unset;
                    right: 0;
                }
            }
        }

        .image-wrapper {
            .prev-arrow {
                transition: opacity $defaultTransitionSpeed $ease-in-out-quad;

                pointer-events: auto;
                opacity: 1;
            }
        }
    }

    &.show-next-arrow {
        .cta-tile {
            display: none;
        }

        .image-wrapper {
            .next-arrow {
                transition: opacity $defaultTransitionSpeed $ease-in-out-quad;

                pointer-events: auto;
                opacity: 1;
            }
        }

        &:hover {
            .image-wrapper {
                .icon-tile-mask {
                    width: 220% !important;
                }

                img {
                    transform: scale(1) translate(-50%, -50%) !important;
                }
            }
        }
    }

    .image-wrapper {
        position: relative;
        aspect-ratio: 426/321;
        overflow: hidden;
        margin: 0 0 30px;
        border-radius: 5px;

        .tag {
            position: absolute;
            top: 15px;
            left: 15px;
            z-index: 1;

            display: inline-block;
            background: map-get($colors-tiles, new-tag);
            padding: 2px 10px;
            border-radius: 24px;

            @include otterco-regular();
            font-size: rem(14);
            line-height: rem(19.6);
            letter-spacing: 0.05em;
            color: map-get($colors-base, text);
        }

        .arrow {
            pointer-events: none;
            opacity: 0;

            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
            z-index: 6;

            display: flex;
            align-items: center;

            &.prev-arrow {
                justify-content: flex-start;

                width: 40%;
                background: linear-gradient(90deg, #132C2A 0%, rgba(19, 44, 42, 0.3) 80%, rgba(19, 44, 42, 0) 100%);

                padding: 0 0 0 var(--gutter-width) ;
            }

            &.next-arrow {
                justify-content: flex-end;

                width: 50%;
                background: linear-gradient(-90deg, #132C2A 0%, rgba(19, 44, 42, 0.3) 80%, rgba(19, 44, 42, 0) 100%);

                padding: 0 var(--gutter-width) 0 0;
            }

            .arrow-controls {
                border: 1px solid map-get($colors-base, text-invert);

                &:hover {
                    background-color: map-get($colors-base, text-invert);

                    svg * {
                        stroke: map-get($colors-base, text);
                    }
                }

                svg * {
                    stroke: map-get($colors-base, text-invert);
                }
            }
        }

        img {
            background-size: cover;
            height: 100%;
            width: 100%;
            max-width: none;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            object-fit: cover;
            transition: transform $slowerTransitionSpeed ease-in-out;
        }
    }

    .text-wrapper {
        .date {
            color: map-get($colors-tiles, text-green);
            text-transform: uppercase;
        }

        .category {
            color: map-get($colors-tiles, special-text);
            text-transform: uppercase;
        }

        .title {
            margin-block: 15px;

            a {
                @include otterco-light();
                color: var(--text-color);
                text-decoration: none;
                text-transform: uppercase;
            }
        }

        .short-description {
            margin: 0;
        }

        .text-content {
            margin: 0 0 30px;

            * {
                margin: 0;
            }
        }

        .username-credits {
            p {
                text-transform: uppercase;
                margin-block: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:before {
                    content: '@';
                }
            }
        }

        .text-author {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 30px;

            .icon-smile {
                margin: 0 5px 0 0;

                svg {
                    path {
                        stroke: var(--svg-color);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $md-min) and (max-width: 1290px) {
        &.activity-tile {
            &.text-tile {
                .inner-wrapper {
                    .text-wrapper {
                        .like-h3 {
                            font-size: rem(36);
                            line-height: rem(40);
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        &.activity-tile {
            &.text-tile {
                display: none;
            }
        }
    }


    @media only screen and (max-width: $xs-max) {
        &.activity-tile,
        &.news-tile,
        &.promotion-tile {
            &.text-tile {
                padding: 30px 30px;
            }

            .infos {
                row-gap: 10px;
            }

            .buttons {
                opacity: 1;
            }

            &.small-tile {
                width: 100%;

                .inner-wrapper {
                    flex-direction: column;

                    .image-wrapper {
                        height: unset;
                    }
                }
            }
        }

        &.news-tile,
        &.promotion-tile {
            .buttons {
                display: flex;
            }
        }

        &:hover {
            .image-wrapper {
                .icon-tile-mask {
                    width: 200%;
                }
            }
        }
    }

}
