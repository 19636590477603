@use "../../../config" as *;
@use "../../../mixins" as *;

.tiles-listing {
    padding-top: 0;

    &.in-transition{
        opacity: 0
    }

    .heading{
        @include col(12);
        @include grid(12, var(--gutter-width));
        justify-content: space-between;
        margin-bottom: 50px;

        h3, .title{
            @include col(7);
        }

        p.title{
            text-transform: uppercase;
            margin: 0;
            align-self: end;
            padding-bottom: 20px;
        }

        hr {
            margin: 0;
            width: 100%;
        }

        .button{
            @include col(5);
            height: 100%;
            display:flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 20px;
        }
    }

    .tiles-wrapper {

        .list {
            transition: opacity 0.2s ease, filter 0.2s ease;
            opacity:1;

            @include grid(var(--column-amount), var(--gutter-width));
            row-gap: 60px;

            .tile{
                @include col(4);
                height: 100%;
            }

            &.in-transition{
                opacity: 0
            }
        }
    }

    &.light-green, &.dark-green{
        .load-more{
            button{
                color: map-get($colors-base, text-invert);
                    &:after{
                        background-color: map-get($colors-base, bg);
                    }
            }
        }
    }

    .load-more{
        margin-block: 50px;
        text-align: center;
        cursor: pointer;
    }

    @media only screen and (min-width: $lg-min){
        .tiles-wrapper{
            .list{
                .tile{
                    .image-wrapper{
                        img{
                            width: 100%;
                        }

                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max){
        .tiles-wrapper {
            .list {
                .tile{
                    @include col(6);
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max){
        &.with-mountain{
            padding-top: 80px;
        }

        .heading{
            h3, .title{
                @include col(12);
            }

            .button{
                justify-content: flex-start;
                @include col(12);
            }
        }

        .tiles-wrapper {
            .list {
                row-gap: 50px;

                .tile{
                    @include col(12);

                    .image-wrapper{
                        margin-bottom: 20px;
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
