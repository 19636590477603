@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

section.list-big-tiles {
    padding-top: 0;

    &.with-mountain {
        padding-top: 10px; /* Leave some space so the filter blur does not crop the div */
    }

    &.dark-green, &.light-green{

        .icon-map-pin{
            svg{
                *{
                    stroke: $white;
                }
            }
        }

        .distance{
            color: map-get($colors-tiles, special-text);
        }
    }
}
