@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

// merging faq-wrapper in this file. It works just like regions in contact - renaming parent wrapper.
.menu-accordion-wrapper {
    position: relative;
    @include grid(var(--column-amount), var(--gutter-width));

    .side-menu-wrapper {
        @include col(4);
        @include col-start(1);

        position: relative;

        &.is-fixed {
            position: fixed;
        }

        .secondary-cta {
            display: block;
            margin: 0 0 16px;
        }

        span {
            &.icon-external-link {
                width: 12.5px;
                margin-top: -1px;
            }
        }
    }

    .categories-wrapper {
        @include col(8);
        @include col-start(5);

        .category-wrapper {
            margin: 0 0 80px;

            &:last-child {
                margin: 0;
            }

            h5 {
                margin: 0;
                padding: 0 0 20px;
                border-bottom: 1px solid map-get($colors-base, border);
            }

            .accordion {
                &:last-child {
                    border-bottom: 1px solid map-get($colors-base, border);
                }

                .centered-content {
                    width: 100%;

                    .accordion-title {
                        .large {
                            font-size: rem(20px);
                            line-height: rem(30px);
                            text-transform: none;
                        }
                    }
                }
            }
        }
    }

    .actions-wrapper {
        display: none;
    }

    .select-wrapper{
        &.primary{
            display: none;
        }
    }


    @media only screen and (max-width: $xs-max) {
        .side-menu-wrapper {
            display: none;
        }
        .select-wrapper {
            &.primary{
                display: block;
            }
            display: block;
            margin-bottom: 50px;
        }
        .categories-wrapper {
            @include col(12);
            @include col-start(1);

            .category-wrapper {
                margin: 0 0 60px;

                &:last-child {
                    margin: 0 0 50px;
                }
            }
        }
        .actions-wrapper {
            display: block;
        }
    }
}

