@import "../../../config";
@import "../../../mixins";
@import "../../../functions";

.book-overlay{
    &.active {
        .wrapper {
            right: 0;
        }
    }

    .backdrop{
        content: ' ';
        position: fixed;
        inset: 0;
        background-color: rgba(0,0,0,0.2);
    }

    .wrapper{
        position: absolute;
        top: 0;
        height: 100vh;
        right: -100%;

        width: 857px;
        transition: right $defaultTransitionSpeed $ease-in-out-quad;

        background: map-get($colors-overlay, background-light);

        padding: 66px 40px 24px;

        .close {
            .icon-close {
                width: 24px;
            }
        }

        form {
            .title {
                margin: 0 0 63px;

                h3{
                    display: inline;
                    line-height: 1.125;
                }

                .select-wrapper {
                    .absolute-wrapper {
                        .icon-arrow-down {
                            right: 0;
                        }
                    }
                }
            }
            .actions {
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin: 0 0 30px;

                .select-wrapper, .input-wrapper {
                    flex: 1 1 33%;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .wrapper {
            width: 100vw;
        }
    }

    @media only screen and (max-width: $xs-max) {
        .wrapper{
            padding: 125px 20px 20px;

            form {
                .title {
                    margin: 0 0 30px;

                    h3{
                        font-size: 2rem;
                        margin: 0 0 10px;
                    }
                }
                .actions {
                    flex-direction: column;
                    gap: 10px;
                    margin: 0 0 40px;

                    .select-wrapper, .input-wrapper {
                        flex: 1 1 100%;
                        margin: 0;
                    }
                }
            }
        }
    }
}
