@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

.dark-green, .light-green {
    .tags {
        .tag{
            background: map-get($colors-base, bg-light-green);
            color: map-get($colors-base, text-invert);
        }
        .additional {
            color: map-get($colors-base, text-invert);
        }
    }
}

.tags {
    pointer-events: none;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;

    .tag{
        display: inline-block;
        background: map-get($colors-base, bg-light-beige);
        padding: 2px 10px;
        border-radius: 24px;
        margin: 0;

        @include otterco-regular();
        font-size: rem(14);
        line-height: rem(19.6);
        letter-spacing: 0.05em;

        &:last-child {
            margin: 0;
        }
    }

    .additional {
        @include otterco-regular();
        font-size: rem(14);
        line-height: rem(19.6);
        letter-spacing: 0.05em;
    }
}
