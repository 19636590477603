@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.input-wrapper{
    position: relative;
    margin-bottom: 20px;
    width: 100%;

    label{
        pointer-events: none;
        position: absolute;
        left: 30px;
        top: 30px;
        transform: translateY(-50%);
        font-size: rem(14);
        line-height: rem(20);
        @include otterco-regular();
    }

    .error-msg{
        margin: 15px 0 0 35px;
        font-size: rem(13);
        line-height: rem(14);
        @include otterco-regular();
        color: map-get($colors-base, error);
        letter-spacing: 0;
        display: flex;
        align-items: start;
        gap: 10px;

        .icon-warning{
            flex: 0 0 auto;
        }
    }

    button.submit {
        position: absolute;
        right: 8px;
        top: 6px;

        padding: 14px 24px;
        font-size: 1rem;
        line-height: 1.125rem;
    }

    span[class^="icon-"]:not(.icon-warning) {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

}

textarea {
    @include otterco-regular();
    font-size: rem(14);
    display: block;
    padding: 20px 30px;
    border: 1px solid map-get($colors-forms, border);
    background: transparent;
    min-height: 150px;
    width: 100%;
    transition: border 0.25s ease;
    border-radius: 40px;
    resize: none;


    &:focus, &.has-value {
        & + label {
            opacity: 0;
        }
    }

    & + label {
        top: 35px;
        left: 25px;
        transform: none;
    }

    &.has-error{
        border: 1px solid map-get($colors-base, error);

        & + label{
            color: map-get($colors-base, error);

            & + .error-msg{
                color: map-get($colors-base, error);
            }
        }
    }

}



input[type=date]{
    color: transparent;
    cursor: pointer;
    text-align: left;

    &.no-label {
        padding: 15px 30px;
    }

    &::-webkit-inner-spin-button,
     &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    &::-webkit-date-and-time-value{ text-align:left; }
    &.has-value{
        transition: all ease 250ms;
        color: map_get($colors-forms, label-active);
        & + label{
            padding: 20px 30px 10px 30px;
        }
    }
    & + label{
        cursor: pointer;
    }
}



input[type=text], input[type=email], input[type=phone], input[type=password], input[type=date]{
    @include otterco-regular();
    font-size: rem(14);
    padding: 30px 30px 10px;
    //padding: 12px 30px;
    border-radius: 40px;
    border: 1px solid map-get($colors-forms, border);
    background: transparent;
    width: 100%;
    transition: border 0.25s ease;
    min-height: 60px;
    box-sizing: border-box;

    &.has-value{
        & + label{
            padding: 0 0 24px 2px;
            font-size: rem(12);
        }
    }

    &.has-error{
        border: 1px solid map-get($colors-base, error);

        & + label{
            color: map-get($colors-base, error);

            & + .error-msg{
                color: map-get($colors-base, error);
            }
        }
    }

    & + label{
        transition: all 0.3s $ease-in-out-quad;
        padding: 0;
        font-size: rem(14);
    }
}


@media only screen and (max-width: $sm-max) {
    .input-wrapper{
        position: relative;
        margin-bottom: 20px;

        label{
            position: absolute;
            left: 30px;
            top: 31px;
            font-size: rem(14);
        }
    }

    input[type=text], input[type=email], input[type=phone], input[type=password], input[type=date] {
        &.has-value{
            & + label{
                padding: 0 0 21px 2px;
            }
        }

        & + label{
            left: 30px;
            top: 30px;
        }
    }
    textarea{
        & + label {
            top: 30px;
            left: 25px;
        }
    }
}

@media only screen and (max-width: $xs-max){
    .input-wrapper{
        margin-bottom: 10px;
    }
}
