@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.groups-form {
    .centered-content {
        > form {
            @include col-start(5);
            @include col(8);

            h2 {
                margin-bottom: 60px;
            }

            button[type=submit] {
                margin-top: 20px;
            }
        }
    }


    @media only screen and (max-width: $sm-max) {
        .centered-content {
            > form {
                @include col-start(1);
                @include col(12);
            }
        }
    }
}
