@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.payment-cart {
    min-height: calc(100vh - 107px);
    background: map-get($colors-base, bg-beige);
    padding-bottom: 0;
    overflow: clip;

    .btn-return {
        display: none;
        @include otterco-semibold();
        font-size: rem(16);
        line-height: rem(24);
        margin: 0 0 30px;
        text-transform: none;

        &:after{
            display: none;
        }

        .icon-arrow-down {
            flex: 0 0 auto;
            width: 14px;
            transform: rotate(-90deg);
            margin: 0 10px 0 0;
            pointer-events: none;
        }
    }

    @media only screen and (max-width: 1220px) {
        .btn-return {
            display: flex;
        }
    }
}
