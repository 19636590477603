@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.subnavigation {
    height: calc(60vh + 167px);
    min-height: 700px;
    max-height: 100vh;
    position: fixed;
    bottom: 100%;
    z-index: -1;
    top: calc(-60vh - 167px);
    left: 0;
    right: 0;

    overflow: hidden;

    background-color: map-get($colors-header, bg-opened-subnavigation);

    box-sizing: border-box;
    padding: calc(75px + 167px) var(--gutter-width) 0;

    @include grid(var(--column-amount), var(--gutter-width));

    transition: top 1s cubic-bezier(.19,1,.22,1);

    pointer-events: none;

    &:before{
        display: block;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(75px + 167px);
        background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgba(247,246,240,1) 30%);
        opacity: 0;
        pointer-events: none;
        z-index: 1;
    }


    svg {
        pointer-events: none;
    }

    &.closed {
        z-index: 1;
        top: calc(-60vh - 167px);
        opacity: 0;
        transition: top 600ms linear, opacity 300ms linear;
    }

    &.open {
        z-index: 2;
        top: 0;
        opacity: 1;
        pointer-events: auto;

        &~ .overlay-background {
            opacity: 0.8;
        }

        &:before{
            transition: opacity $defaultTransitionSpeed linear $defaultTransitionSpeed;
            opacity: 1;
        }
    }

    .subnavigation-column{
        opacity: 0;
        transform: translateY(-30px);
    }

    .links {
        @include col-start(1);
        @include col(8);

        &.has-view-all-link {
            li {
                button{
                    text-transform: uppercase;
                }
                &:last-child {
                    a {
                        display: inline-block;
                        position: relative;
                        @include otterco-regular();
                        font-size: rem(14);
                        line-height: rem(18.27);
                        padding-bottom: 3px;
                        text-transform: uppercase;
                        &:after {
                            display: block;
                            content: ' ';
                            position: absolute;

                            top: 100%;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: map-get($colors-buttons, tertiary-color);

                            transition: background-color $defaultTransitionSpeed $ease-in-out-quad;
                        }

                        &:hover, &.active {
                            font-size: rem(14);
                            line-height: rem(18.27);
                            color: map-get($colors-buttons, tertiary-hover-color);

                            &:after {
                                background-color: map-get($colors-buttons, tertiary-hover-color);
                            }
                        }
                    }
                }
            }
        }

        li {
            text-transform: uppercase;
            &:before {
                display: none;
            }

            a, button {
                display: flex;
                align-items: center;
                @include otterco-light();
                font-size: rem(40);
                line-height: rem(52.2);
                color: map-get($colors-base, text);
                text-decoration: none;

                transition: all $defaultTransitionSpeed $ease-in-out-quad;

                margin: 0 0 27px;

                &:before {
                    display: none;
                }

                &:hover, &.active {
                    font-size: rem(54);
                    line-height: rem(70.47);
                    color: map-get($colors-header, subnavigation-text-hover);

                    .icon-arrow-down {
                        opacity: 1;
                    }
                }

                .icon-arrow-down {
                    flex: 0 0 auto;
                    width: 47px;
                    transform: rotate(90deg);
                    margin: 0 0 0 30px;
                    opacity: 0;
                    pointer-events: none;
                    transition: all $defaultTransitionSpeed $ease-in-out-quad;

                    svg {
                        path {
                            stroke: map-get($colors-header, subnavigation-text-hover);;
                        }
                    }
                }
            }

            &:last-child {
                a {
                    margin: 0;
                }
            }
        }
    }

    .image-container {
        @include col-start(9);
        @include col(4);

        img{
            //max-height: 353px;
            max-height: 50vh;
            width: 100%;
            object-fit: cover;
        }
    }
}


