@mixin adieu($font-weight: normal){
    font-family: 'Adieu', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin otterco-regular($font-weight: normal){
    font-family: 'Otterco-Regular', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin otterco-light($font-weight: 300){
    font-family: 'Otterco-Light', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin otterco-medium($font-weight: 500){
    font-family: 'Otterco-Medium', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin otterco-semibold($font-weight: 600){
    font-family: 'Otterco-SemiBold', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin otterco-bold($font-weight: bold){
    font-family: 'Otterco-Bold', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin otterco-extrabold($font-weight: bold){
    font-family: 'Otterco-ExtraBold', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

