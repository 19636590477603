@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-image-video {
    --column-list: var(--column-amount);
    overflow: hidden;
    padding-top: 0;
    
    &.with-mountain {
        padding-top: 0;
    }
    
    &.playing{
        .centered-content{
            .video-content{
                img{
                    opacity: 0;
                    pointer-events: none;
                }
                button{
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }
    }
    
    .centered-content {
        overflow: visible;
        
        .media-wrapper {
            position: relative;
            width: calc(100% + (var(--gutter-width)*2));
            left: calc(0px - var(--gutter-width));
            margin: 0 auto;
            transition: transform 0.6s $ease-out-quint;
        }

        .title {
            text-transform: uppercase;
            margin: 0 0 60px;
            align-self: end;
            padding-bottom: 20px;
            width: auto;

            border-bottom: 1px solid var(--border-color);
        }

        .video-content {
            width: 100%;
            position: relative;
            overflow: hidden;

            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-width: 100%;
                min-height: 100%;
                z-index: 2;
                transition: all $defaultTransitionSpeed $ease-in-out-quad;
            }

            .secondary-cta{
                position: absolute;
                bottom: 40px;
                right: 50px;
                z-index: 3;
                transition: all $defaultTransitionSpeed $ease-in-out-quad;

                svg {
                    path{
                        transition: all $defaultTransitionSpeed $ease-in-out-quad;
                    }
                }

                &:hover{
                    svg {
                        path{
                            stroke: map-get($colors-buttons, secondary-inverse-hover-color);
                        }
                    }
                }
            }

            video {
                width: 100%;
                z-index: 1;
            }

            img, video {
                border-radius: 0;
                transition: border-radius 0.6s $ease-out-quint;
            }
        }

        .image-content {
            width: 100%;

            img {
                border-radius: 5px;
                width: 100%;
            }
        }
    }

    &.scrolled {
        .media-wrapper {
            transform: scale(var(--container-width-percentage));
            img, video {
                border-radius: 20px;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content {
            .media-wrapper {
                width: 100%;
                left: auto;
                margin: 0 auto;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        &.with-mountain {
            padding: 0;
        }

        .centered-content {
            .title {
                margin: 0 0 10px;
            }

            .video-content {
                .secondary-cta{
                    bottom: 19px;
                    right: 20px;
                }
            }
        }
    }
}
