@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

.hero {
    position: relative;
    padding: 167px 0 124px;

    &.with-mountain {
        padding: 167px 0 124px;
    }

    &.lower{
        padding: 221px 0 124px;

        &.with-mountain {
            padding: 221px 0 124px;
        }

        &.type_3, &.type_4 {
            min-height: 990px;
        }
    }

    &.beige, &.blue {
        .centered-content {
            path {
                stroke: map-get($colors-base, text);
            }
        }
    }

    &.light{
        .centered-content {
            .breadcrumb-nav {
                ul {
                    li {
                        color: map-get($colors-base, text-invert);

                        a {
                            .label {
                                color: map-get($colors-base, text-invert);
                            }
                        }
                    }
                }
            }

            path {
                stroke: map-get($colors-base, text-invert);
            }

            .text-wrapper{
                .title{
                    h1{
                        color: map-get($colors-base, text-invert);
                    }
                    .subtitle{
                        color: map-get($colors-base, text-invert);

                        p{
                            color: map-get($colors-base, text-invert);
                        }
                    }
                }

                .description{
                   p{
                       color: map-get($colors-base, text-invert);
                   }
                }
            }
        }
    }

    &:not(.light-blue){
            a:not(.primary-cta):not(.secondary-cta) {
                color: var(--text-color);
            }
    }

    &.type_1, &.type_2 {
        min-height: 572px;

        .text-wrapper {
            .title {
                @include col(11);
            }
        }
    }

    &.type_3, &.type_4 {
        min-height: 936px;

        &:before {
            position: absolute;
            content: "";
            inset: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 25.96%);
            background-blend-mode: screen;
            z-index: 1;
        }

        &:after {
            position: absolute;
            content: "";
            inset: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 30%);
            background-blend-mode: screen;
            z-index: 1;
        }
    }

    .centered-content {
        z-index: 4;
    }

    .gallery-button {
        position: absolute;
        bottom: 270px;
        right: 40px;
        z-index: 5;

        .icon-gallery {
            flex: 0 0 auto;
            margin-top: -3px;
            margin-left: 8px;

            svg{
                width: auto;
            }
        }

        &:hover{
            .icon-gallery{
                svg{
                    rect{
                        fill: map-get($colors-base, text);
                    }
                }
            }
        }
    }

    .breadcrumb-nav {
        path {
            stroke: var(--svg-color);
        }
    }

    .text-wrapper {
        @include grid(var(--column-amount), var(--gutter-width));
        @include col-start(1);
        @include col(12);

        .title {
            @include col-start(1);
            @include col(10);

            h1 {
                margin: 0;
            }

            .subtitle {
                font-size: rem(16);

                h5{
                    margin-top: 20px;
                }

                p {
                    display: flex;
                    text-transform: uppercase;

                    .icon-map-pin {
                        margin-right: 4px;
                    }

                    span {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .description {
            @include col-start(2);
            @include col(5);
            margin-top: 50px;
            font-size: rem(20);

            p {
                margin: 0;
            }
        }

        .cta-button {
            margin-top: 50px;
            @include col-start(2);

            span {
                color: var(--text-color)
            }
        }

    }

    .background-wrapper {
        position: absolute;
        inset: 0;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform-origin: bottom;
        }
    }

    .mountain-icon-wrapper {
        position: absolute;
        z-index: 2;
        bottom: 0;

        margin: 0 0 -2px;
        height: 422px;

        .icon-spacer {
            margin: 0 0 0 -1200px;
            height: 100%;
            width: unset;
            //transform: translate3d(-1200px, 0px, 0px) !important;

            svg {
                height: 100%;
                width: unset;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        padding: 81px 0 100px;

        &.with-mountain {
            padding: 81px 0 100px;
        }

        &.lower{
            padding: 160px 0 100px;

            &.with-mountain {
                padding: 160px 0 100px;
            }

            &.type_3, &.type_4 {
                min-height: 600px;
            }
        }

        &.type_1, &.type_2 {
            min-height: 0;

            .text-wrapper {
                .title {
                    @include col(12);
                }
            }
        }

        &.type_3, &.type_4 {
            min-height: 600px;

            &:before {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) -1.69%, rgba(0, 0, 0, 0) 41.43%);
            }

            .mountain-icon-wrapper {
                .icon-spacer {
                    margin: 0 0 0 -600px;
                }
            }
        }

        .text-wrapper {
            .title{
                @include col(12);
            }
            .description {
                margin: 40px 0 0;
                @include col-start(1);
                @include col(12);
            }
            .subtitle {
                p {
                    font-size: rem(16);
                }
            }

            .cta-button {
                margin: 30px 0 0;
                @include col-start(1);
            }
        }

        .mountain-icon-wrapper {
            height: 150px;

            .icon-spacer {
                margin: 0;
            }
        }

        .gallery-button {
            bottom: 100px;
            right: 20px;
        }
    }
}
