@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.secondary-navigation {
    z-index: 3;
    transition: background-color $defaultTransitionSpeed $ease-in-out-quad;

    .centered-content {
        ul {
            height: 66px;
            box-sizing: border-box;

            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;

            margin: 0;
            padding: 20px 0;

            li {
                padding: 0;
                margin: 0 8px 0 0;

                &:before {
                    display: none;
                }

                .secondary-cta {
                    &.small {
                        font-size: rem(14);
                        line-height: rem(24);
                        padding: 1px 10px;
                        text-transform: unset;
                    }
                }

                &.language-link{
                    margin: 0 0 0 auto;

                    a{
                        @include otterco-regular();
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        visibility: hidden;
        overflow: hidden;
        height: 0;
    }
}
