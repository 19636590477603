@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

section.footer-navigation {
    padding: 100px 0;
    border-top: 1px solid map-get($colors-base, border);
    background-color: map-get($colors-footer, bg);

    .footer-navigation-column {
        @include col(2);

        &:first-child{
            @include col-start(2);
        }

        &:last-child {
            @include col(4);

            p{
                margin: 30px 0;
            }
        }

        h3, .like-h3 {
            margin: 0 0 30px;
        }

        ul {
            padding: 0;

            li {
                overflow: hidden;
                padding: 0;
                margin: 0 0 16px;

                &:before {
                    display: none;
                }

                &:last-child{
                    margin: 0;
                }

                a{
                    @include otterco-light();
                    font-size: rem(16);
                    line-height: rem(24);
                    text-decoration: none;
                    color: map-get($colors-buttons, link);
                    transition: color $defaultTransitionSpeed $ease-in-out-quad;

                    &:hover{
                        color: map-get($colors-buttons, link-hover);
                    }
                }
            }
        }

        .socials {
            a{
                margin: 0 30px 0 0;
                text-decoration: none;

                svg{
                    path {
                        stroke: map-get($colors-buttons, link);
                        transition: stroke $defaultTransitionSpeed $ease-in-out-quad;


                    }
                }

                &:hover{
                    svg{
                        path {
                            stroke: map-get($colors-buttons, link-hover);
                        }
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .footer-navigation-column {
            @include col(3);
            h6{
                font-size: rem(20);
                line-height: rem(20);
            }
            &:first-child {
                @include col-start(1);
            }

            &:last-child {
                @include col(3);
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding: 70px 0 60px;

        .footer-navigation-column {
            @include col(12);

            h6{
                font-size: rem(26);
                line-height: rem(26);
            }

            &:first-child {
                @include col-start(1);
            }

            &:last-child {
                @include col(12);
                margin-top: 45px;
            }
        }

    }
}

