@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.contact-form{
    padding-block: 100px;
    .title-content{

        @include col-start(1);
        h2{
            margin-block: 0 60px;
        }
    }
    form{
        @include col(8);
        @include col-start(5);

        button[type="submit"]{
            margin-block: 20px 0;
            font-size: rem(18);
            line-height: rem(23);
        }

        .success{
            @include otterco-regular();
            margin-top: 40px;
            margin-bottom: 0;
            letter-spacing: 0;
            font-size: rem(14);
        }

        &.is-valid{
            .success{
                display: block;
            }
        }

    }

    @media only screen and (max-width: $xs-max){
        padding-block: 0 100px;
        .title-content{
            h2{
                margin-block: 0 20px;
            }
        }
        form{
            @include col-start(1);
            @include col(12);
        }

    }
}
